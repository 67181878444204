import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import DoesStringNotEqual from "../../functions/DoesStringNotEqual";
import RegexLengthCheck from "../../functions/RegexLengthCheck";
import RegexNameCheck from "../../functions/RegexNameCheck";
import DoesStringEqual from "../../functions/DoesStringEqual";

export default async function CreateNewAuthAccount(auth, functions, accountDetails, setAccountDetails,
                                                   viewModals, setViewModals,
                                                   values, setValues, handleCloseLoading, updateErrorList) {

    const resetModalState = () => {
        setValues({
            firstName: '',
            lastName: '',
            dob: null,
            leadSource: '-None-',
            email: '',
            confirmEmail: '',
            password: '',
            confirmPassword: '',
            showPassword: false,
            showConfirmPassword: false
        });

        setViewModals({
            ...viewModals,
            signInModal: false,
            signUpModal: false,
            messageModal: true,
            modalMessage: ['Congratulations, you have now created a KeyWard account!',
                'We have sent you a verification email to the address you provided.']
        });
    };

    const handleSuccess = () => {
        resetModalState();
        handleCloseLoading();
    };

    const handleError = (error) => {
        handleCloseLoading();
        updateErrorList(error);
    };

    const validateInputs = () => {
        if (!DoesStringNotEqual(values.dob, null, "You must enter your date of birth") ||
            !RegexLengthCheck(values.firstName, "First Name", 64) ||
            !RegexLengthCheck(values.lastName, "Last Name", 64) ||
            !RegexNameCheck(values.firstName, "First Name") ||
            !RegexNameCheck(values.lastName, "Last Name") ||
            !RegexLengthCheck(values.email, "Email", 64) ||
            !RegexLengthCheck(values.password, "Password", 64) ||
            !DoesStringEqual(values.email.trim(), values.confirmEmail.trim(), "Emails do not match") ||
            !DoesStringEqual(values.password, values.confirmPassword, "Passwords do not match")
        ) {
            throw new Error("Input validation failed");
        }
    };

    const createNewUserAccount = async () => {
        const func = httpsCallable(functions, 'createNewUserAccount');
        const accountData = {
            user: {
                firstName: values.firstName,
                lastName: values.lastName,
                dob: values.dob,
                leadSource: values.leadSource
            },
            preferences: {
                menuDrawerAnchor: accountDetails.preferences.menuDrawerAnchor,
                menuButtonAnchor: accountDetails.preferences.menuButtonAnchor
            }
        };
        return await func(accountData);
    };

    try {
        validateInputs();
        await createUserWithEmailAndPassword(auth, values.email.trim(), values.password);
        await createNewUserAccount();
        await sendEmailVerification(auth.currentUser);
        handleSuccess();
        await signOut(auth);

    } catch (error) {
        handleError(error);
    }
}
