export default function RedactEmail(email, isLoggedIn){
    if (isLoggedIn && email!==null) {

        const len = email.split("@").slice(0, 1).join("");
        let replaceStar = 0;
        let value = 0;

        if (len.length % 2 !== 0) {
            const tmpLen = len.length - 1;
            replaceStar = tmpLen / 2
            value = (tmpLen / 2) + 1
        } else {
            replaceStar = len.length / 2
            value = len.length / 2
        }

        let star = "";
        for (let i = 0; i < replaceStar; i++) {
            star += "*"
        }

        const replaceValue = "$1" + star + "@$2"
        const pattern1 = "(.{";
        const pattern2 = "})[\\w.-]+@([\\w.]+\\w)";
        const rule = new RegExp(`${pattern1}${value}${pattern2}`, "g");
        return email.replace(rule, replaceValue)
    }else {
        return "No User"
    }
}
