import PropTypes from 'prop-types';
import {Grid, Typography, Box} from "@mui/material";
import * as React from "react";

const defaultStyles = {
    margin: 0.5,
    borderRadius: 15,
    width: 150,
    color: 'whitesmoke',
    borderColor: '#000000'
}

const statusStyles = {
    configuring: { bgcolor: '#B22222', ...defaultStyles },
    requested: { bgcolor: '#DAA520', ...defaultStyles },
    preparing: { bgcolor: '#ECB510', ...defaultStyles },
    inTransitToYou: { bgcolor: '#FFA500', ...defaultStyles },
    receivedByYou: { bgcolor: '#008000', ...defaultStyles },
    inTransitToUs: { bgcolor: '#87CEFA', ...defaultStyles },
    receivedByUs: { bgcolor: '#006400', ...defaultStyles },
    secured: { bgcolor: '#00008B', ...defaultStyles },
    requestedReturn: { bgcolor: '#FF00FF', ...defaultStyles },
    preparingReturn: { bgcolor: '#00FFFF', ...defaultStyles },
    returnInTransit: { bgcolor: '#40E0D0', ...defaultStyles },
    returnNotReceived: { bgcolor: '#FF0000', ...defaultStyles },
    wardInactive: { bgcolor: '#808080', ...defaultStyles },
}

function toCamelCase(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export default function WardCardStatus({status}) {
    const formattedStatus = status ? toCamelCase(status) : 'wardInactive';
    const chosenStyle = statusStyles[formattedStatus] || statusStyles['wardInactive'];
    return (
        <Grid xs={6} lg={6} md={6} sm={6} xl={6} item>
            <Box sx={{ width: "100%", display:"flex", justifyContent:"center" }}>
                <Typography sx={chosenStyle} id={"ward-status"} align="center" gutterBottom>
                    {status || 'NA'}
                </Typography>
            </Box>
        </Grid>
    )
}

WardCardStatus.propTypes = {
    status: PropTypes.string.isRequired
}
