import React from 'react';
import { Grid, Card, CardContent, useMediaQuery, Box, Typography, useTheme } from '@mui/material';
import ReactMarkdown from 'react-markdown';

let globalImageIndex = 0;

export const ArticleCard = ({ blocks }) => {
    const theme = useTheme();
    const isMediumScreenOrLarger = useMediaQuery(theme.breakpoints.up('md'));

    const imageStyle = () => ({
        width: isMediumScreenOrLarger ? '30%' : '70%', // Adjusted width on small screens
        margin: isMediumScreenOrLarger ? '0 30px 20px' : '0 15%', // Centered on small screens
        borderRadius: 30,
        float: isMediumScreenOrLarger ? (globalImageIndex % 2 === 0 ? 'right' : 'left') : 'none',
    });

    const textStyle = (textData) => ({
        textAlign: /^#\s/.test(textData) ? 'center' : 'left', // Center text if it's a title
        margin: '10px 0',
        // Add padding to the side of the text that's close to an image on large screens
        paddingLeft: isMediumScreenOrLarger && globalImageIndex % 2 === 0 ? '30px' : '0',
        paddingRight: isMediumScreenOrLarger && globalImageIndex % 2 !== 0 ? '30px' : '0',
    });

    return (
        <Grid item xs={12} spacing={2}>
            <Card>
                <CardContent>
                    {blocks.map((block, index) => {
                        const isTextBlock = block.type === 'text' && block.data;
                        const isImageBlock = block.type === 'image' && block.data;

                        if (isImageBlock) {
                            globalImageIndex++;
                        }

                        return (
                            <React.Fragment key={`${block.type}-${index}`}>
                                {isTextBlock && (
                                    <Typography component="div" variant="body1" sx={textStyle(block.data)}>
                                        <ReactMarkdown>{block.data}</ReactMarkdown>
                                    </Typography>
                                )}
                                {isImageBlock && (
                                    <Box
                                        component="img"
                                        src={block.data}
                                        sx={imageStyle()}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </CardContent>
            </Card>
        </Grid>
    );
};
