import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Container, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Popover} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function SignInModal({
                                        signInToAccount,
                                        viewModals, setViewModals,
                                        loadingOn, loadingOff,
                                        updateErrorsList, modalSx
                                    }) {

    const [values, setValues] = React.useState({
        email: '',
        password: '',
        showPassword: false,
    });

    const signIn = () => {
        try {
            loadingOn();
            signInToAccount(values, setValues);
        } catch (error) {
            loadingOff();
            console.log("error signIn", error)
            updateErrorsList(error)
        }
    }

    const handleSignInModalChange = (prop) => (event) => {
        try {
            setValues({...values, [prop]: event.target.value});
        } catch (error) {
            console.log("error handleSignInModalChange", error)
            updateErrorsList(error)
        }
    };

    const handleClickShowPassword = () => {
        try {
            setValues({
                ...values,
                showPassword: !values.showPassword,
            });
        } catch (error) {
            console.log("error handleClickShowPassword", error)
            updateErrorsList(error)
        }
    };

    const handleMouseDownPassword = (event) => {
        try {
            event.preventDefault();
        } catch (error) {
            console.log("error handleMouseDownPassword", error)
            updateErrorsList(error)
        }
    };

    const handleSignInModalClose = () => {
        setViewModals({
            ...viewModals,
            signInModal: false
        });
        setValues({
            ...values,
            email: '',
            password: '',
            showPassword: false,
        });
    }

    const handleSignUpModalOpen = () => {
        setViewModals({
            ...viewModals,
            signInModal: false,
            signUpModal: true
        });

    }

    const handleForgotPasswordModalOpen = () => {
        setViewModals({
            ...viewModals,
            signInModal: false,
            forgotPasswordModal: true
        });

    }

    return (
        <Popover
            open={viewModals.signInModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleSignInModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="sign in popover"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                            <Typography id="sign-in-modal-title" variant="h6" component="h2">
                                Sign into your account
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign={"center"}>
                            <FormControl sx={{marginBottom: 2}} id="outlined-email" label="Email Address"
                                         variant="outlined"
                                         fullWidth={true}>
                                <InputLabel htmlFor="email-label">Email Address</InputLabel>
                                <OutlinedInput
                                    id="email-input"
                                    inputProps={{
                                        'aria-label': 'email input',
                                    }}
                                    value={values.email}
                                    onChange={handleSignInModalChange('email')}
                                    label="Email Address"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign={"center"}>
                            <FormControl id="outlined-password" label="Password" variant="outlined" fullWidth={true}>
                                <InputLabel htmlFor="password-label">Password</InputLabel>
                                <OutlinedInput
                                    id="password-input"
                                    inputProps={{
                                        'aria-label': 'password input',
                                    }}
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleSignInModalChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                            <Button variant="contained" onClick={signIn} label="sign in button"
                                    aria-label="sign in button">Sign In</Button>
                        </Grid>
                        <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                            <Button variant="contained"
                                    onClick={handleSignInModalClose} label="cancel button"
                                    aria-label="cancel button">Cancel</Button>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                            <Button aria-label="forgot password button" onClick={handleForgotPasswordModalOpen}>Forgot
                                Password?</Button>
                            <Button aria-label="dont have account button" onClick={handleSignUpModalOpen}>Don't have an
                                account?</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Popover>
    );
}
