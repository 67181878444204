import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Container, Grid, Popover} from "@mui/material";


export default function MessageModal({viewModals, setViewModals, modalSx}) {

    const handleMessageModalClose = () => {
        try {
            setViewModals({...viewModals, messageModal: false, modalMessage: []})
        } catch (error) {
            console.log("error handleMessageModalClose", error);
        }
    }

    const printMessage = () => {
        return viewModals.modalMessage.map((msg, index) => (
            <Grid key={index} item xs={12} lg={12} md={12} sm={12} xl={12}>
                <Typography id={"message" + index} variant="h6" component="h2" textAlign="center">
                    {msg}
                </Typography>
            </Grid>))
    }

    return (
        <Popover
            open={viewModals.messageModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleMessageModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="message modal"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    {printMessage()}
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                        <Button id="close-message" aria-label="close message" variant="contained"
                                onClick={handleMessageModalClose}>OK</Button>
                    </Grid>
                </Grid>
            </Container>
        </Popover>
    );
}
