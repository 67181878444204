import React from "react";
import {Card, CardContent, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export default function PointCard({index, point, updateErrorsList}) {

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const transactionType = point.transactionType
        .replaceAll("_", " ")
        .split(" ")
        .map((word) => capitalizeFirstLetter(word))
        .join(" ");

    return (
            <Card>
                <CardContent>
                    <Box textAlign="center">
                        <Typography variant="h6" component="div" gutterBottom>
                            {transactionType}
                        </Typography>
                        <Typography >
                            Amount: {point.points.toLocaleString()}
                        </Typography>
                        <Typography >
                            Date: {new Date(point.timestamp.seconds * 1000).toLocaleDateString()}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>

    );
}
