import {decryptMap} from "../encryptionService/EncryptionService";
import { createWardEntityWithName} from "../../factories/WardFactory";

export async function setEncryptedWards(functions, querySnapshot, setWardDetails) {
    const wards = new Map();
    const wardNames = new Map();
    querySnapshot.forEach((ward) => {
        wardNames.set(ward.id, ward.get("name").data)
    })
    if (wardNames.size > 0) {
        const decryptedData = await decryptMap(functions, Object.fromEntries(wardNames));
        const map = new Map();
        Object.entries(decryptedData.data).forEach(([key, value]) => map.set(key, value));
        querySnapshot.forEach((ward) => {
            wards.set(ward.id, createWardEntityWithName(ward.id, ward.data(),map.get(ward.id)));
        });
        setWardDetails(wards);
    } else {
        setWardDetails(wards);
    }
}
