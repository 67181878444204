import React, {useState} from 'react';
import OrganiseCard from './OrganiseCard';
import WardCard from "./WardCard";
import {Grid} from "@mui/material"; // import the OrganizeCard component

export default function WardCards({
                                      wardsData,
                                      setWardsData,
                                      updateWardName,
                                      updateWardStatus,
                                      viewModals,
                                      setViewModals,
                                      updateErrorsList
                                  }) {
    const [sortBy, setSortBy] = useState({sort: 'name', sortOrder: 'asc'}); // default sort by 'name'

    const sortWards = (wardList) => {
        // Sort the list.
        wardList.sort((a, b) => {
            // handle case where the values to compare might be strings
            if (typeof a[sortBy.sort] === 'string' || typeof b[sortBy.sort] === 'string') {
                const comparison = a[sortBy.sort].localeCompare(b[sortBy.sort]);
                return sortBy.sortOrder === 'asc' ? comparison : -comparison;
            }

            // for numerical or date comparisons
            const comparison = a[sortBy.sort] > b[sortBy.sort] ? 1 : -1;
            return sortBy.sortOrder === 'asc' ? comparison : -comparison;
        });

        return wardList;
    };


    try {
        const wardList = [];
        wardsData.forEach((ward) => {
            wardList.push(ward.ward)
        })

        const sortedWardList = sortWards(wardList); // sort the ward list

        return (
            <>
                {wardList.length > 0 && (
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <OrganiseCard setSortBy={setSortBy} updateErrorsList={updateErrorsList}/>
                    </Grid>
                )}
                {sortedWardList.map((ward, index) => (
                    <Grid  key={ward.reference.replaceAll(' ', '-') + '-grid'} item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <WardCard
                        key={ward.reference.replaceAll(' ', '-') + '-card'}
                        ward={ward}
                        wardsData={wardsData} setWardsData={setWardsData}
                        updateWardName={updateWardName} updateWardStatus={updateWardStatus}
                        viewModals={viewModals} setViewModals={setViewModals}
                        updateErrorsList={updateErrorsList}
                        index={index + 1}
                    />
                    </Grid>
                ))}
            </>
        );
    } catch (error) {
        console.log("error keyLabel", error)
        updateErrorsList(error)
    }
}
