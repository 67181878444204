import {signOut} from "firebase/auth";
import {initAccount} from "../../factories/InitAccountFactory";

export default function SignOutOfAuthAccount(auth, setAccountDetails, setWardDetails, viewModals, setViewModals) {

    const handleMessageModalOpen = () => {
            setViewModals({...viewModals, messageModal: true, modalMessage: ["You have been signed out"]})
    }

    return signOut(auth).then(() => {
        setAccountDetails(initAccount());
        setWardDetails(new Map([]))
        handleMessageModalOpen();
    });
}
