import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {Container, FormControl, Grid, InputLabel, OutlinedInput, Popover} from "@mui/material";


export default function ForgotPasswordModal({
                                                forgotPassword,
                                                viewModals,
                                                setViewModals,
                                                loadingOn, loadingOff,
                                                updateErrorsList, modalSx
                                            }) {

    const [email, setEmail] = React.useState('');

    const handleTypeEmail = (event) => {
        try {
            setEmail(event.target.value)
        } catch (error) {
            console.log("error handleTypeEmail", error)
            updateErrorsList(error)
        }
    }

    const handleForgotPassword = (event) => {
        loadingOn();
        forgotPassword(email)
            .then(() => {
                handleForgotPasswordSubmitSuccess();
            })
            .catch((error) => {
                loadingOff();
                console.log("error handleForgotPassword", error)
                updateErrorsList(error)
            });
    };

    const handleForgotPasswordSubmitSuccess = () => {
        setViewModals({
            ...viewModals,
            forgotPasswordModal: false,
            messageModal: true,
            modalMessage: ['You have been sent a password reset email']
        });
        setEmail('');
        loadingOff();
    }


    const handleForgotPasswordModalClose = () => {
        setViewModals({
            ...viewModals,
            forgotPasswordModal: false,
            signInModal: true,
        });
        setEmail('');
    }

    return (
        <Popover
            open={viewModals.forgotPasswordModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleForgotPasswordModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="forgot password modal"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                            <Typography id="enter-email-address" variant="h6" component="h2">
                                Enter your Email Address
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign={"center"}>
                            <FormControl id="outlined-basic-email" label="Email" variant="outlined"
                                         fullWidth={true}>
                                <InputLabel htmlFor="email">Email</InputLabel>
                                <OutlinedInput
                                    id="email-input"
                                    inputProps={{
                                        maxLength: 64,
                                        'aria-label': 'email input',
                                    }}
                                    onChange={handleTypeEmail}
                                    value={email}
                                    label="Email"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                            <Button variant="contained" onClick={handleForgotPassword}
                                    aria-label="confirm forgot password button">Confirm Forgot
                                Password</Button>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                            <Button variant="contained"
                                    onClick={handleForgotPasswordModalClose}
                                    aria-label="cancel forgot password button">Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Popover>
    );
}
