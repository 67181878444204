import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Checkbox, FormControl, FormControlLabel, FormGroup, Grid} from "@mui/material";

export default function AddressDisclaimer(state) {

    const handleNextPage = () => {
        state.handleModalPage(state.nextPage);
    }

    const handleBackPage = () => {
        state.handleModalPage(state.prevPage);
    }

    const handleClosePage = () => {
        state.handleModalClose();
    }

    const agreeAddressDisclaimer = (prop) => () => {
        try {
            state.setValues({...state.values, [prop]: !state.values.addressDisclaimer});
        } catch (error) {
            console.log("error agreeAddressDisclaimer", error)
            state.updateErrorsList(error)
        }
    };

    const note1 = () => {
        return <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                Create New Ward - Address Disclaimer
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography id='disclaimer-note-1'
                        align={"center"}
                        gutterBottom>
                You will be asked to enter a delivery address. This is the address for the Ward delivery.
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography id='disclaimer-note-2'
                        align={"center"}
                        gutterBottom>
                This will be the address for both sending the new Ward Pack
                and for when you request the Ward to be returned.
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography id='disclaimer-note-3'
                        align={"center"}
                        gutterBottom>
                For security purposes this address can not be changed after submission.
            </Typography>
            <FormControl>
                <FormGroup>
                    <FormControlLabel id="disclaimer-checkbox" control={<Checkbox/>}
                                      checked={state.values.addressDisclaimer}
                                      onChange={agreeAddressDisclaimer('addressDisclaimer')}
                                      label="By ticking this box I confirm I have read and understood the above"/>
                </FormGroup>
            </FormControl>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    <Button  variant="contained" id='next-create-ward-page' disabled={!state.values.addressDisclaimer}
                            onClick={handleNextPage}>Next</Button>
                    <Button  variant="contained" id='back-create-ward-page'
                            onClick={handleBackPage}>Back</Button>
                    <Button  variant="contained" id='cancel-create-ward-page'
                            onClick={handleClosePage}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }

    return note1();
}
