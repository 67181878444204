export default function RegexLengthCheck(string, fieldName, maxLength) {
    const pattern1 = "^(.*){1,";
    const pattern2 = "}$";
    const rule = new RegExp(`${pattern1}${maxLength}${pattern2}`, "i");

    if (string === '' || string.length < 1) {
        throw new Error(fieldName + " can not be empty")
    } else if (!rule.test(string)) {
        throw new Error(fieldName + " must be no more than " + maxLength + " characters")
    } else {
        return true
    }
}
