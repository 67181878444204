import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Grid } from '@mui/material';
import MyEmailCard from "./MyEmailCard";
import MyPasswordResetCard from "./MyPasswordResetCard";
import MyAccountDeleteCard from "./MyAccountDeleteCard";

export default function MyDetailsGroup(props) {
    const {
        updateErrorsList
    } = props;
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h4" align="center" fontWeight="bold" color="primary">
                                My Details
                            </Typography>
                            <Grid container justifyContent="center" marginTop={2}>
                                <Button align="center" onClick={() => setIsExpanded(!isExpanded)}>
                                    {isExpanded ? "Hide Details" : "Show Details"}
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
            </Grid>
            {isExpanded && (
                <>
                    <Grid item>
                        <MyEmailCard {...props} />
                    </Grid>
                    <Grid item>
                        <MyPasswordResetCard{...props} />
                    </Grid>
                    <Grid item>
                        <MyAccountDeleteCard{...props} />
                    </Grid>
                </>
            )}
        </Grid>
    );
}
