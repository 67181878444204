import { sendEmailVerification, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import Welcome from "../../functions/Welcome";
import {contentToJSON} from "../../factories/ContentFactory";
import {initAccount} from "../../factories/InitAccountFactory";

export default async function SignInAuthAccount(auth, db, functions, values, setValues, viewModals, setViewModals,
                                                setAccountDetails, handleCloseLoading, updateErrorList) {

    const handleSignInModalClose = () => {
        handleCloseLoading();
        setViewModals(prevModals => ({ ...prevModals, signInModal: false }));
    }

    const handleUserNotVerified = () => {
        handleCloseLoading();
        setViewModals(prevModals => ({
            ...prevModals,
            signInModal: false,
            messageModal: true,
            modalMessage: [
                'You are not able to log into your account until you have verified your email address.',
                'We have sent you an email, if you are unable to see it try checking the spam folder'
            ]
        }));
    }

    try {
        const { user } = await signInWithEmailAndPassword(auth, values.email.trim(), values.password);
        setValues({
            ...values,
            email: '',
            password: '',
            showPassword: false,
        });

        if (user.emailVerified) {
            handleSignInModalClose();

            const docRef = doc(db, "users", auth.currentUser.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                if (!docSnap.get("EmailVerifiedSyncComplete").valueOf()) {
                    const syncEmailVerifiedFunc = httpsCallable(functions, 'syncEmailVerification');
                    syncEmailVerifiedFunc();
                }

                if (docSnap.get("EmailHasBeenUpdated") !== false) {
                    const now = Timestamp.now();
                    const threeDaysAgo = new Timestamp(
                        now.seconds - 3 * 24 * 60 * 60,
                        now.nanoseconds
                    );

                    if (docSnap.get("EmailHasBeenUpdated").seconds > threeDaysAgo.seconds) {
                        const syncUpdatedEmailFunc = httpsCallable(functions, 'syncUpdatedEmail');
                        syncUpdatedEmailFunc(docSnap.get("CustomerType").valueOf());
                    }
                }

                if (docSnap.get("user.data") === undefined) {
                    Welcome(auth.currentUser, docSnap.get("user.firstName"), setViewModals);
                } else {
                    const decryptContentFunc = httpsCallable(functions, 'decryptContent');
                    const decryptedContent = await decryptContentFunc(contentToJSON(docSnap.get("user.data")));

                    const userJson = JSON.parse(decryptedContent.data.toString());
                    Welcome(auth.currentUser, userJson.firstName, setViewModals);
                }
            }

        } else {
            await sendEmailVerification(user);
            await signOut(auth);
            handleUserNotVerified();
            setAccountDetails(initAccount());
        }

    } catch (error) {
        handleCloseLoading();
        updateErrorList(error);
    }
}
