// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const RecaptchaProfile = {
    recaptchaToken: process.env.REACT_APP_RECAPTCHA_KEY
};

export default RecaptchaProfile;
