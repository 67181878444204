import {createCheckoutSession} from "@stripe/firestore-stripe-payments";

export default function CheckoutSession(payments, price, paymentType=null, qty =0) {
    const checkoutSession = async (payments, price, paymentType) => {
        let checkoutBody = {}
        if (paymentType==='onetime_purchase'){
            checkoutBody = {
                mode: "payment",
                price: price,
                quantity: qty,
                allow_promotion_codes: false,
                success_url: window.location.origin,
                cancel_url: window.location.origin,
                metadata: {
                    paymentType: paymentType
                },
            }
        } else if (paymentType==='subscription_purchase') {
            checkoutBody = {
                price: price,
                allow_promotion_codes: true,
                success_url: window.location.origin,
                cancel_url: window.location.origin,
                metadata: {
                    paymentType: paymentType
                },
            }
        }
        const session = await createCheckoutSession(payments, checkoutBody);
        window.location.assign(session.url);
    }
    checkoutSession(payments, price, paymentType);
}
