import {getFirestore, initializeFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getFunctions} from "firebase/functions";
import firebaseConfig from "./FirebaseConfig";
import {initializeApp} from "firebase/app";
import FirebaseEmulators from "./FirebaseEmulators";
import {initializeAppCheck, ReCaptchaEnterpriseProvider} from "firebase/app-check";

import { getStripePayments } from "@stripe/firestore-stripe-payments";

import recaptchaProfile from "./RecaptchaProfile";
import {useEffect, useState} from "react";

export default function FirebaseStart() {
    const app = initializeApp(firebaseConfig);

    if (window.Cypress){
        initializeFirestore(app, {experimentalForceLongPolling: true})
    }
    const auth = getAuth();
    const functions = getFunctions(app,"europe-west2");
    const db = getFirestore(app);
    const payments = getStripePayments(app, {
        productsCollection: "products",
        customersCollection: "users",
    });

    function initAppCheck(app) {
        initializeAppCheck(app, {
            provider: new ReCaptchaEnterpriseProvider(recaptchaProfile.recaptchaToken),
            isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
        });
    }

    const [started, setStarted] = useState(false);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development' && !started) {
            FirebaseEmulators(db, auth, functions);
            // Assuming 'self' is defined or needs to be changed to 'window' if in a browser context
            window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
            initAppCheck(app);
            setStarted(true);
        } else if (process.env.NODE_ENV === 'production') {
            initAppCheck(app);
        }
    }, [app, auth, db, functions, started]);

    return {auth, functions, db, payments}
}
