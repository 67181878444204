export function initShippingValuesFactory() {
    return {
        shippingRef: '',
        shippingBusinessName: '',
        shippingLine1: '',
        shippingLine2: '',
        shippingTown: '',
        shippingPostcode: ''
    }
}
