import {doc, updateDoc} from "firebase/firestore";
import {createWard} from "../../factories/WardFactory";
import {encryptContent} from "../encryptionService/EncryptionService";

export default function updateWardName(auth, db, functions, ward) {
    const updateWardDetails = async () => {
        if (!auth.currentUser) return;
            try {
                const name = await encryptContent(functions, ward.name)
                const ref = doc(db, "users", auth.currentUser.uid, "wards", ward.id);
                await updateDoc(ref,
                    createWard(ward.id, name, ward.location,
                        ward.reference, ward.status, ward.created, ward.lastModified));
                return { success: true };
            } catch (error) {
                console.error(`Error updating encrypted ward name: ${error}`);
                return { success: false, error: error.message };
            }
    }
    return updateWardDetails();
}
