import * as  React from 'react';
import {FormControl, FormLabel, Card, Grid, Radio, RadioGroup} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from "@mui/material/Button";
import {createPreferences} from "../../factories/PreferencesFactory";
import {createUser} from "../../factories/UserFactory";
import {createAccount} from "../../factories/AccountFactory";

export default function MenuDrawerAnchorCard({
                                                 currentUser, accountData, setAccountData,
                                                 updateAccountPreferences, updateErrorsList
                                             }) {


    const labelLeft = 'left'
    const labelRight = 'right'
    const labelTop = 'top'
    const labelBottom = 'bottom'

    const [change, setChange] = React.useState(false);

    const handleMenuDrawerAnchorChange = (event) => {
        try {
            const user = createUser(accountData.firstName, accountData.lastName,
                accountData.dob, accountData.leadSource)
            const updatedPreferences = createPreferences(event.target.value, accountData.preferences.menuButtonAnchor);
            setAccountData(createAccount(user, updatedPreferences))
            if (currentUser) {
                setChange(true)
            }
        } catch (error) {
            console.log("error handleMenuDrawerAnchorChange", error)
            updateErrorsList(error)
        }
    };

    const displayChange = () => {
        try {
            if (change) {
                return (
                    <>
                    <Grid item xs={3} sm={3} md={6} lg={6} xl={6}>
                        <Button variant="contained" disabled={!change} onClick={submitUpdate}>Save</Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={6} lg={6} xl={6}>
                        <Button variant="contained" onClick={cancelUpdate}>Cancel</Button>
                    </Grid>
                    </>
                )}
        } catch (error) {
            console.log("error displayChange", error);
            updateErrorsList(error)
        }
    };

    const submitUpdate = () => {
        setChange(false)
        updateAccountPreferences(accountData)
    }
    const cancelUpdate = () => {
        setChange(false);
    }

    return (
            <Card key="menuDrawerAnchorForm">
                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <FormControl>
                        <Grid item xs={12} textAlign="center" sx={{mt: 2}}>
                            <FormLabel id="navigation-menu-display">
                                Navigation Menu Display
                            </FormLabel>
                        </Grid>
                        <Grid container>
                            <RadioGroup
                                aria-label="navigation menu display"
                                name="controlled-radio-buttons-group"
                                value={accountData.preferences.menuDrawerAnchor}
                                onChange={handleMenuDrawerAnchorChange}
                            >
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item xs={3} sm={3} md={6} lg={6} xl={6}>
                                        <FormControlLabel aria-label="nav menu left" value={labelLeft}
                                                          control={<Radio/>}
                                                          label={labelLeft}/>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={6} lg={6} xl={6}>
                                        <FormControlLabel aria-label="nav menu right" value={labelRight}
                                                          control={<Radio/>}
                                                          label={labelRight}/>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={6} lg={6} xl={6}>
                                        <FormControlLabel aria-label="nav menu top" value={labelTop} control={<Radio/>}
                                                          label={labelTop}/>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={6} lg={6} xl={6} sx={{mb: 2}}>
                                        <FormControlLabel aria-label="nav menu bottom" value={labelBottom}
                                                          control={<Radio/>}
                                                          label={labelBottom}/>
                                    </Grid>
                                    {displayChange()}
                                </Grid>
                            </RadioGroup>
                        </Grid>
                    </FormControl>
                </Grid>
            </Card>
    );
}
