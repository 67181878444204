import {Card, CardContent, Collapse, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import RedirectToBillingPortalButton from "../billingComponents/RedirectToBillingPortalButton";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useState} from "react";


const PointsInfo = ({title, children}) => (
    <Typography sx={{mt: 3}} variant="h6"  align="center" fontWeight="bold" mb={3}>
        {title}:{" "}
        <Typography component="span" color="text.primary" fontSize="h5.fontSize">
            {children}
        </Typography>
    </Typography>
)

function ExpandMoreIcon() {
    return null;
}

export default function MySubscriptionCard({
                                               currentUser,
                                               subscription,
                                               loadingOn,
                                               isExtensionBoxVisible,
                                               setExtensionBoxVisible,
                                               functions,
                                               updateErrorsList
                                           }) {

    const [isExpanded, setIsExpanded] = useState(false);

    if (!currentUser) {
        return null;
    }

    if (!subscription || !subscription.items || !subscription.items[0] || !subscription.items[0].price || !subscription.items[0].price.product) {
        return null;
    }

    const currentPeriodEnd = subscription.current_period_end?.seconds
        ? new Date(subscription.current_period_end.seconds * 1000).toLocaleDateString()
        : 'N/A';

    return (
            <Card>
                <CardContent>
                    <Typography variant="h4" component="div" gutterBottom color="primary" align="center" fontWeight="bold">
                        My Subscription
                    </Typography>
                    <Divider/>
                    <Grid container justifyContent="space-evenly">
                        <PointsInfo title="Level">
                            <Typography component="span" color="text.primary" fontSize="h5.fontSize">
                                {subscription.items[0].price.product.name || 'N/A'}
                            </Typography>
                        </PointsInfo>
                        <PointsInfo title="Status">
                            <Typography component="span" color="text.primary" fontSize="h5.fontSize">
                                {subscription.status === 'trialing' ? 'active' : subscription.status || 'N/A'}
                            </Typography>
                        </PointsInfo>
                    </Grid>
                    <Divider/>
                    <PointsInfo title="Renews on">
                        <Typography component="span" color="text.primary" fontSize="h5.fontSize">
                            {currentPeriodEnd}
                        </Typography>
                    </PointsInfo>
                    <Divider/>
                    <Box display="flex" justifyContent="center">
                        <Button onClick={() => setIsExpanded(!isExpanded)} endIcon={<ExpandMoreIcon/>}>
                            {isExpanded ? 'Show Less' : 'Show Subscription Details'}
                        </Button>
                    </Box>
                    <Collapse in={isExpanded}>
                        <PointsInfo title="Product Description">
                            {subscription.items[0].price.product.description.split(",").map((point, index) => (
                                <Typography key={index} component="li" color="text.primary" fontSize="h5.fontSize"
                                            align="left">
                                    {point.trim()}
                                </Typography>
                            ))}
                        </PointsInfo>
                        <Divider/>
                        <PointsInfo title="Product Price (in GBP)">
                            <Typography component="span" color="text.primary" fontSize="h5.fontSize">
                                {subscription.items[0].price.unit_amount ? subscription.items[0].price.unit_amount / 100 : 'N/A'}
                            </Typography>
                        </PointsInfo>
                    </Collapse>
                    <Box display="flex" justifyContent="center">
                        <RedirectToBillingPortalButton loadingOn={loadingOn} functions={functions}/>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Button onClick={() => setExtensionBoxVisible(!isExtensionBoxVisible)}>
                            {isExtensionBoxVisible ? "Hide Extend Subscription" : "Extend Subscription Using WARD"}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
    );
}
