import { useState, useEffect, useCallback } from 'react';
import { collection, onSnapshot, orderBy, query, limit, startAfter, where } from 'firebase/firestore';

export default function FetchPointTransactions(auth, db, setPointsTransactions, filter) {
    const [currentUser, setCurrentUser] = useState(null);
    const [lastDocument, setLastDocument] = useState(null);

    const loadMorePoints = useCallback(() => {
        if (!lastDocument) return; // Do not proceed if all documents have been fetched
        const pointsRef = collection(db, "users", currentUser.uid, "points");
        let transactions;
        if (filter === 'All') {
            transactions = query(pointsRef, orderBy("timestamp", "desc"), startAfter(lastDocument), limit(30));
        } else {
            transactions = query(pointsRef, where("transactionType", "==", filter), orderBy("timestamp", "desc"), startAfter(lastDocument), limit(30));
        }

        const pointsUnsub = onSnapshot(transactions, (querySnapshot) => {
            let transactions = [];
            querySnapshot.forEach((docSnapshot) => {
                transactions.push(docSnapshot.data());
            });

            // Get the last document
            setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setPointsTransactions(prevTransactions => [...prevTransactions, ...transactions]); // append new transactions
        });

        return () => {
            pointsUnsub();
        }
    }, [currentUser, db, setPointsTransactions, lastDocument, filter]); // added filter to dependencies

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
        });
        return () => unsubscribe(); // Cleanup on unmount
    }, [auth]);

    useEffect(() => {
        if (currentUser) {
            const pointsRef = collection(db, "users", currentUser.uid, "points");
            let transactions;
            if (filter === 'All') {
                transactions = query(pointsRef, orderBy("timestamp", "desc"), limit(30));
            } else {
                transactions = query(pointsRef, where("transactionType", "==", filter), orderBy("timestamp", "desc"), limit(30));
            }

            const pointsUnsub = onSnapshot(transactions, (querySnapshot) => {
                let transactions = [];
                querySnapshot.forEach((docSnapshot) => {
                    transactions.push(docSnapshot.data());
                });

                // Get the last document
                if (querySnapshot.docs.length > 0) {
                    setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
                }

                setPointsTransactions(transactions);
            });

            return () => {
                pointsUnsub();
            }
        }
    }, [currentUser, db, setPointsTransactions, filter]); // added filter to dependencies

    // Call loadMorePoints initially and whenever filter changes
    useEffect(() => {
        loadMorePoints();
    }, [loadMorePoints]);

    return loadMorePoints; // return the function to load more data
};
