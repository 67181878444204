import { useEffect, useState, useCallback } from "react";
import { collection, query, onSnapshot, where, getDocs} from "firebase/firestore";

export default function FetchSubscription(auth, db, setSubscription) {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setSubscription(null)
            setCurrentUser(user);
        });
        return () => unsubscribe(); // Cleanup on unmount
    }, [auth, setSubscription]);

    const viewSubscription = useCallback(async () => {
        if (currentUser) {
            const subscriptionsRef = collection(db, "users", currentUser.uid, "subscriptions");
            const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));

            onSnapshot(q, async (snapshot) => {
                if (!snapshot.empty && snapshot.docs.length > 0) {
                    // In this implementation we only expect one active or trialing subscription to exist.
                    const doc = snapshot.docs[0];
                    const subscriptionData = doc.data();

                    // Retrieve account benefits
                    const accountBenefitsRef = collection(db, "account_benefits");
                    const benefitQ = query(
                        accountBenefitsRef,
                        where("type", "==", subscriptionData.role),
                        where("renewal", "==", subscriptionData.items[0].plan.interval),
                        where("interval_count", "==", subscriptionData.items[0].plan.interval_count)
                    );
                    const benefitsSnapshot = await getDocs(benefitQ);

                    if (!benefitsSnapshot.empty && benefitsSnapshot.docs.length > 0) {
                        subscriptionData.benefits = benefitsSnapshot.docs[0].data();
                    } else {
                        console.log(`No account benefits found for role "${subscriptionData.role}" 
                         interval "${subscriptionData.items[0].plan.interval}" and interval count "${subscriptionData.items[0].plan.interval_count}".`);
                    }

                    setSubscription(subscriptionData);
                } else {
                    console.log("No active or trialing subscriptions found.");
                }
            });
        }
    }, [currentUser, db, setSubscription])

    useEffect(() => {
        if (currentUser) {
            viewSubscription();
        }
    }, [currentUser, viewSubscription]);
};
