import {httpsCallable} from "firebase/functions";

export default function UpdateWardStatus(functions, id, status) {
    const statusUpdate = httpsCallable(functions, 'updateWardStatus');
    const wardId = {
        id: id,
        status: status
    }

    return statusUpdate(wardId)
        .then((result) => {
            const data = result.data;
            return {
                response: data.response
            };
        })
        .catch((error) => {
            console.error(`Error updating ward status: ${error}`);
            // Return or throw error, based on your error handling strategy
        });
}
