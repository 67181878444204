 import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Grid} from "@mui/material";

export default function WardReview(state) {

    const handleNextPage = () => {
        state.handleModalPage(state.nextPage);
    }

    const handleBackPage = () => {
        state.handleModalPage(state.prevPage);
    }

    const handleClosePage = () => {
        state.handleModalClose();
    }

    const wardReview = () => {
        return <Grid container spacing={2} direction="row" alignItems="center" justify="center">
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                    Create New Ward - Ward Review
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='ward-name-key'
                            align={"center"}
                            gutterBottom>
                    Name:
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='ward-name-value'
                            align={"center"}
                            gutterBottom>
                    {state.values.name}
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='location-key'
                            align={"center"}
                            gutterBottom>
                    Location:
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='location-value'
                            align={"center"}
                            gutterBottom>
                    {state.values.location}
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='postage-name-key'
                            align={"center"}
                            gutterBottom>
                    Postage Name:
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='postage-name-value'
                            align={"center"}
                            gutterBottom>
                    {state.values.postageName}
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography id='address-line1-key'
                            align={"center"}
                            gutterBottom>
                    Address Line 1:
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography id='address-line1-value'
                            align={"center"}
                            gutterBottom>
                    {state.values.line1}
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='address-line2-key'
                            align={"center"}
                            gutterBottom>
                    Address Line 2:
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='address-line2-value'
                            align={"center"}
                            gutterBottom>
                    {state.values.line2}
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='address-town-key'
                            align={"center"}
                            gutterBottom>
                    Town:
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='address-town-value'
                            align={"center"}
                            gutterBottom>
                    {state.values.town}
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='address-postcode-key'
                            align={"center"}
                            gutterBottom>
                    Postcode:
                </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                <Typography
                            id='address-postcode-value'
                            align={"center"}
                            gutterBottom>
                    {state.values.postcode}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container direction="column" alignItems="center" justify="center"
                  textAlign="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <Button
                            id='submit-create-ward'
                            variant="contained" onClick={handleNextPage}>Request New
                        Ward</Button>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <Button  variant="contained"
                             id='back-create-ward-page'
                            onClick={handleBackPage}>Back</Button>
                    <Button  variant="contained"
                             id='cancel-create-ward-page'
                            onClick={handleClosePage}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }

    return wardReview();
}
