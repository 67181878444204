import * as React from 'react';
import {FormControl, Grid, InputLabel, OutlinedInput, Typography, Button} from "@mui/material";

export default function VerifyShippingAddress({style,
                                                  values,
                                                  setValues,
                                                  updateErrorsList,
                                                  prevPage,
                                                  handleModalPage,
                                                  handleSubmit,
                                                  handleModalClose}) {

    const handleClosePage = () => {
        handleModalClose();
    }

    const handleBackPage = () => {
        handleModalPage(prevPage)
    }

    const handleSetWardShippingInfo = (prop) => (event) => {
        try {
            setValues({...values, [prop]: event.target.value});
        } catch (error) {
            console.log("error handleSetWardShippingInfo", error)
            updateErrorsList(error)
        }
    };


    const detailsNotEntered = () => {
        return values.shippingBusinessName === ''
            || values.shippingLine1 === ''
            || values.shippingLine2 === ''
            || values.shippingTown === ''
            || values.shippingPostcode === '';
    }

    return (
        <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                    Enter the Ward Shipping Address
                </Typography>
                <Typography 
                            id="ship-add-info-1"

                            align={"center"}
                            gutterBottom>
                    This is to found on the white return envelope.
                </Typography>
                <Typography 
                            id="ship-add-info-2"

                            align={"center"}
                            gutterBottom>
                    This is to verify the Ward will be sent to the correct address.
                </Typography>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <FormControl id="ward-postage-name-label" label="ward-postage-name-label" variant="outlined"
                                 fullWidth={true}>
                        <InputLabel htmlFor="ward-postage-name-label">Business Name</InputLabel>
                        <OutlinedInput
                            id="ward-postage-name-label"
                            inputProps={{
                                maxLength: 11,
                                'aria-label': 'business name'
                            }}
                            value={values.shippingBusinessName}
                            onChange={handleSetWardShippingInfo('shippingBusinessName')}
                            label="Business Name"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                    <FormControl id="ward-line1-label" label="ward-line1-label" variant="outlined" fullWidth={true}>
                        <InputLabel htmlFor="ward-line1-label">Address Line 1</InputLabel>
                        <OutlinedInput
                            id="ward-line1-label"
                            inputProps={{maxLength: 10,  'aria-label': 'address line 1'}}
                            value={values.shippingLine1}
                            onChange={handleSetWardShippingInfo('shippingLine1')}
                            label="Address Line 1"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                    <FormControl id="ward-line2-label" label="ward-line2-label" variant="outlined" fullWidth={true}>
                        <InputLabel htmlFor="ward-line2-label">Address Line 2</InputLabel>
                        <OutlinedInput
                            id="ward-line2-label"
                            inputProps={{maxLength: 11, 'aria-label': 'address line 2'}}
                            value={values.shippingLine2}
                            onChange={handleSetWardShippingInfo('shippingLine2')}
                            label="Address Line 2"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                    <FormControl id="ward-town-label" label="ward-town-label" variant="outlined" fullWidth={true}>
                        <InputLabel htmlFor="ward-town-label">Town</InputLabel>
                        <OutlinedInput
                            id="ward-town-label"
                            inputProps={{maxLength: 10, 'aria-label': 'town'}}
                            value={values.shippingTown}
                            onChange={handleSetWardShippingInfo('shippingTown')}
                            label="Town"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                    <FormControl id="ward-postcode-label" label="ward-postcode-label" variant="outlined"
                                 fullWidth={true}>
                        <InputLabel htmlFor="ward-name-label">Post Code</InputLabel>
                        <OutlinedInput
                            id="ward-postcode-label"
                            inputProps={{maxLength: 7, 'aria-label': 'post code'}}
                            value={values.shippingPostcode}
                            onChange={handleSetWardShippingInfo('shippingPostcode')}
                            label="Postcode"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            </Grid>
            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    <Button variant="contained" style={style.button}  id='submit-confirm-receipt-button'
                            onClick={handleSubmit} disabled={detailsNotEntered()}>Confirm
                        Received</Button>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    <Button variant="contained" style={style.button}  id='back-confirm-receipt-button' onClick={handleBackPage}>Back</Button>
                    <Button variant="contained" style={style.button}  id='cancel-confirm-receipt-button'
                            onClick={handleClosePage}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
