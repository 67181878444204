import DoesStringEqual from "./DoesStringEqual";

export default function AddressCheck(values, updateErrorsList) {
    const handleCheckAddress = () => {
        try {
            if (DoesStringEqual(values.postageName.toLowerCase().trim(), values.postageNameConf.toLowerCase().trim(), "The Postage Name does not match")) {
                if (DoesStringEqual(values.line1.toLowerCase().trim(), values.line1Conf.toLowerCase().trim(), "The Address Line 1 does not match")) {
                    if (DoesStringEqual(values.line2.toLowerCase().trim(), values.line2Conf.toLowerCase().trim(), "The Address Line 2 does not match")) {
                        if (DoesStringEqual(values.town.toLowerCase().trim(), values.townConf.toLowerCase().trim(), "The Town does not match")) {
                            if (DoesStringEqual(values.postcode.toLowerCase().trim(), values.postcodeConf.toLowerCase().trim(), "The Postcode does not match")) {
                                return true;
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.log("error handleCheckAddress", error)
            updateErrorsList(error)
        }
    }
    return handleCheckAddress()
}
