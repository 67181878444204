import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import {
    Card,
    CardContent,
    Grid,
    Collapse,
    Button,
    useMediaQuery,
} from "@mui/material";
import {useTheme} from "@mui/system";
import {useParams} from "react-router-dom";
import {LearnCard} from "./cards/LearnCard";
import {ArticleCard} from "./cards/ArticleCard";
import {ContentCardR} from "./cards/ContentCardR";
import {ContentCardL} from "./cards/ContentCardL";
import {ExpandedCard} from "./cards/ExpandedCard"

const parseContentCardsMarkdown = (md) => {
    const lines = md.split('\n');
    const parsed = [];

    let title = null;
    let description = [];
    let expandedDescription = [];
    let buttonData = null;
    let image = null;
    let isCollectingDescription = false;
    let isCollectingExpandedDescription = false;

    const pushCurrentSection = () => {
        if (title || description.length || expandedDescription.length || buttonData || image) {
            parsed.push({
                title,
                description: description.join('\n\n'),
                expandedDescription: expandedDescription.length ? expandedDescription.join('\n\n') : null,
                buttonData,
                image,
            });
            title = null;
            description = [];
            expandedDescription = [];
            buttonData = null;
            image = null;
            isCollectingDescription = false;
            isCollectingExpandedDescription = false;
        }
    };

    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();

        if (line.startsWith('# ')) {
            // New section
            pushCurrentSection();
            title = line; // Keep the '# ' for Markdown formatting
            isCollectingDescription = true;
        } else if (line.startsWith('---')) {
            // Breaker line
            description.push('---');
        } else if (line.startsWith('## ')) {
            // Subheading
            description.push(line); // Keep the '## ' for Markdown formatting
        } else if (line.startsWith('![')) {
            // Image
            const imageUrl = `${process.env.PUBLIC_URL}/mdImages/${line.match(/\((.*?)\)/)[1].replace(/^\.\//, '')}`;
            image = imageUrl;
        } else if (isCollectingDescription) {
            // Button link handling
            const linkMatch = line.match(/\[(.*?)\]\((.*?)\)/);
            if (linkMatch) {
                buttonData = { text: linkMatch[1], url: linkMatch[2] };
                isCollectingDescription = false;
                isCollectingExpandedDescription = true;
            } else {
                description.push(line);
            }
        } else if (isCollectingExpandedDescription) {
            // Collect expanded content until the next section
            if (line.startsWith('# ')) {
                // Start of a new section
                pushCurrentSection();
                title = line; // Start new section
                isCollectingDescription = true;
            } else {
                expandedDescription.push(line);
            }
        }
    }

    pushCurrentSection(); // Push the last parsed section, if any exists

    return parsed.filter(
        (section) => section.title || section.description.length || section.expandedDescription.length || section.buttonData || section.image
    ); // Remove empty sections
};

const parseArticleCardsMarkdown = (md) => {
    const lines = md.split('\n');
    let parsed = [];

    let isCollectingDescription = false;
    let blocks = [];

    const pushCurrentSection = () => {
        if (blocks.length) {
            parsed.push({ blocks });
            blocks = [];
        }
    };

    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();

        if (line.startsWith('# ')) {
            // Push the current section if we start a new one
            pushCurrentSection();

            // Start a new section
            blocks.push({ type: 'text', data: line });
            isCollectingDescription = true;
        } else if (line.startsWith('![')) {
            const imageUrl = line.match(/\((.*?)\)/)[1];
            blocks.push({ type: 'image', data: imageUrl });
        } else if (isCollectingDescription) {
            // Handle empty lines and non-empty lines
            if (line !== '') {
                blocks.push({ type: 'text', data: line });
            }
        }
    }

    // Push the last section
    pushCurrentSection();

    return parsed.filter(section => section.blocks.length);
};

export function parseLearnCardsMarkdown(md) {
    const sections = md.split("# ").filter(Boolean);
    const parsedSections = [];

    sections.forEach((section) => {
        const lines = section.split('\n').filter(Boolean);
        const title = lines[0];
        const subjects = lines.slice(1).map((line) => {
            const match = line.match(/\[(.*?)\]\((.*?)\)/);
            if (match) {
                return {name: match[1], path: match[2]};
            } else {
                return {name: line.replace("-", "").trim(), path: ''};
            }
        });

        parsedSections.push({title, subjects});
    });

    return parsedSections;
}

export const LearnCardContentViewer = ({file}) => {
    const [parsedSections, setParsedSections] = useState([]);

    useEffect(() => {
        axios.get(file)
            .then((response) => {
                setParsedSections(parseLearnCardsMarkdown(response.data));
            });
    }, [file]);

    useEffect(() => {
        if (parsedSections.length > 0) {
            adjustExternalLinks();
        }
    }, [parsedSections]);

    return (
        <Grid container spacing={2}>
            {parsedSections.map((section, index) => (
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} key={index}>
                    <LearnCard title={section.title} subjects={section.subjects}/>
                </Grid>
            ))}
        </Grid>
    );
};

export function CardContentViewer({ file }) {
    const [parsedSections, setParsedSections] = useState([]);
    const [expandedSections, setExpandedSections] = useState({});

    const theme = useTheme();
    const isSmOrLarger = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        axios.get(file).then((response) => {
            setParsedSections(parseContentCardsMarkdown(response.data));
        });
    }, [file]);

    const handleExpandClick = (index) => {
        setExpandedSections((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    return parsedSections.map((section, index) => {
        const { title, description, expandedDescription, image, buttonData } = section;
        const isEvenOrSmall = index % 2 === 0 || !isSmOrLarger;
        const isExpanded = expandedSections[index] || false;

        return (
            <React.Fragment key={`section-${index}`}>
                {isEvenOrSmall ? (
                    <ContentCardR
                        title={title}
                        description={description}
                        buttonData={buttonData}
                        image={image}
                        isExpanded={isExpanded}
                        expandedDescription={expandedDescription}
                        onExpandClick={() => handleExpandClick(index)}
                    />
                ) : (
                    <ContentCardL
                        title={title}
                        description={description}
                        buttonData={buttonData}
                        image={image}
                        isExpanded={isExpanded}
                        expandedDescription={expandedDescription}
                        onExpandClick={() => handleExpandClick(index)}
                    />
                )}
                {expandedDescription && (
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <ExpandedCard expandedDescription={expandedDescription} />
                    </Collapse>
                )}
            </React.Fragment>
        );
    });
}

export function ArticleContentViewer() {
    const [parsedSections, setParsedSections] = useState([]);
    const { path } = useParams();

    useEffect(() => {
        const fetchMarkdown = async () => {
            const filePath = `/markdown/${path}.md`;
            try {
                const response = await axios.get(filePath);
                setParsedSections(parseArticleCardsMarkdown(response.data));
            } catch (error) {
                console.error("Error fetching markdown:", error);
            }
        };
        fetchMarkdown();
    }, [path]);

    useEffect(() => {
        if (parsedSections.length > 0) {
            adjustExternalLinks();
        }
    }, [parsedSections]);

    return (
        <Grid container spacing={2}>
            {parsedSections.map((section, index) => (
                <ArticleCard key={`section-${index}`} blocks={section.blocks} />
            ))}
        </Grid>
    );
}

export function OpenCloseContentViewer({file}) {
    const [markdown, setMarkdown] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        axios.get(file)
            .then((response) => {
                setMarkdown(response.data);
            });
    }, [file]);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Card>
            <CardContent sc={{minWidth: '100vw'}}>
                <Grid container alignContent={"center"} justifyContent={"center"} alignItems={"stretch"}
                      direction={"row"} spacing={0}>
                    <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
                        <ReactMarkdown>
                            {markdown.split('\n').slice(0, 7).join('\n')}
                        </ReactMarkdown>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <ReactMarkdown>
                                {markdown.split('\n').slice(7).join('\n')}
                            </ReactMarkdown>
                        </Collapse>
                        <Grid container justifyContent="center">
                            <Button onClick={handleExpandClick}>
                                {isExpanded ? 'Hide' : 'Read More'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
const adjustExternalLinks = () => {
    const links = document.querySelectorAll('a');
    links.forEach((link) => {
        const href = link.getAttribute('href');
        if (href && (href.startsWith('http://') || href.startsWith('https://'))) {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
        }
    });
};
