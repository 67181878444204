import {useState} from "react";
import {Button, Card, CardMedia, Grid, IconButton, Input, Typography} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export default function SubscriptionExtensionBox({
                                                    subscription, pointBalance, extendSubscription,
                                                     viewModals, setViewModals,
                                                     updateErrorsList
                                                 }) {
    const [qty, setQty] = useState(1);

    const increment = () => {
        setQty(prevQty => prevQty + 1);
    }

    const decrement = () => {
        if (qty > 1) {
            setQty(prevQty => prevQty - 1);
        }
    }

    const displayConfirmation = (totalPrice) => {
        try {
            setViewModals({
                ...viewModals,
                genericConfirmationModal: true,
                genericConfirmationMessage: [
                    `Extend Subscription ${qty} ${qty > 1 ? 'Months' : 'Month'} for ${totalPrice.toFixed(0)} WARD`
                ],
                genericConfirmationFunction: async function () {
                    if (totalPrice <= pointBalance) {
                        try {
                            const res = await extendSubscription(qty)
                            if (res.data.status !== 200) {
                                updateErrorsList(new Error(res.data.message))
                            }

                        } catch (error) {
                            updateErrorsList(error)
                        }
                    } else {
                        updateErrorsList(new Error("You do not have enough WARD to make this purchase"))
                    }
                }
            });
        } catch (error) {
            console.log("error handleSignInModal", error)
            updateErrorsList(error)
        }
    }

    const logo = process.env.PUBLIC_URL + '/kwdLogo.jpg'

    if (
        subscription &&
        (subscription.status === "active" || subscription.status === "trialing")
    ) {
        const totalPrice = qty * subscription.benefits.ward_month;
        return (
            <Grid item xs={12}>
                    <Card key={subscription.name + '-extension'}>
                        <Grid container direction="column" spacing={2} sx={{p: 3}}>
                            {/* Plan Info */}
                            {logo && (
                                <Grid item xs={12}>
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            objectFit: 'contain',
                                            maxHeight: 100,
                                            maxWidth: 100,
                                            margin: 'auto',
                                        }}
                                        image={logo}
                                        alt={subscription.name + '-extension'}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    align="center"
                                >
                                    Extend Subscription {qty}{' '}
                                    {qty > 1 ? 'Months' : 'Month'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" align="center">
                                    Extend your subscription using WARD for additional months
                                </Typography>
                            </Grid>

                            {/* Quantity Controls */}
                            <Grid
                                container
                                item
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                xs={12}
                            >
                                <IconButton
                                    onClick={decrement}

                                    aria-label="decrease quantity"
                                >
                                    <RemoveIcon/>
                                </IconButton>
                                <Input value={qty} readOnly/>
                                <IconButton
                                    onClick={increment}

                                    aria-label="increase quantity"
                                >
                                    <AddIcon/>
                                </IconButton>
                            </Grid>

                            {/* Pricing and Purchase */}
                            <Grid
                                container
                                item
                                direction="column"
                                alignItems="center"
                                spacing={2}
                                xs={12}
                            >
                                <Grid item>
                                    <Typography variant="h4" align="center">
                                        {totalPrice.toFixed(0)} WARD
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        onClick={() => displayConfirmation(totalPrice)}
                                    >
                                        Buy
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
            </Grid>
        );
    } else {
        return null;
    }
}
