import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {Container, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Popover} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";


export default function ReauthenticateModal({
                                                reauthenticateAccount,
                                                viewModals, setViewModals,
                                                loadingOn, loadingOff,
                                                updateErrorsList, modalSx
                                            }) {


    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const reauthenticate = () => {
        loadingOn();
        reauthenticateAccount(values.password).then(() => {
            loadingOff();
            handleModalClose();
        }).catch((error) => {
            loadingOff();
            updateErrorsList(error)
        });
    }

    const handleReauthenticateModalChange = (prop) => (event) => {
        try {
            setValues({...values, [prop]: event.target.value});
        } catch (error) {
            console.log("error handleReauthenticateModalChange", error)
            updateErrorsList(error)
        }
    };

    const handleClickShowPassword = () => {
        try {
            setValues({
                ...values,
                showPassword: !values.showPassword,
            });
        } catch (error) {
            console.log("error handleClickShowPassword", error)
            updateErrorsList(error)
        }
    };

    const handleMouseDownPassword = (event) => {
        try {
            event.preventDefault();
        } catch (error) {
            console.log("error handleMouseDownPassword", error)
            updateErrorsList(error)
        }
    };

    const handleModalClose = () => {
        setViewModals({
            ...viewModals,
            reauthenticateModal: false
        });
        setValues({
            ...values,
            password: '',
            showPassword: false,
        });
    }

    return (
        <Popover
            open={viewModals.reauthenticateModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="reauthenticate account"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Reauthenticate your account
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign={"center"}>
                            <FormControl id="outlined-basic-password" label="Password" variant="outlined"
                                         fullWidth={true}>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    id="password-input"
                                    inputProps={{
                                        'aria-label': 'password input',
                                    }}
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleReauthenticateModalChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                            <Button variant="contained"
                                    onClick={reauthenticate}>Reauthenticate</Button>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                            <Button variant="contained"
                                    onClick={handleModalClose}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Popover>
    );
}
