import {Button, Grid} from "@mui/material";
import * as React from 'react';
import Divider from "@mui/material/Divider";

export default function ReturnNotReceived(state) {


    const inTransitForLessThanTenDays = (ward) => {
        let diff = ward.lastModified - Date.now();
        let diffDays = (diff / (1000 * 3600 * 24))*-1;
        return diffDays < 10;
    }

    const returnNotReceivedButton = () => {
        if (state.ward.status === "return in transit") {
            return <Grid container
                         alignItems="center"
                         direction="column"
                         spacing={2}>
                <Divider orientation="horizontal" flexItem style={{margin: 6}}/>
                <Button disabled={inTransitForLessThanTenDays(state.ward)} variant="contained" id='returnNotReceived'
                        onClick={e => state.handleDisplayReturnNotReceivedModal(state.ward.id, true)}>Return Not Received</Button>
            </Grid>
        }
    }

    return (returnNotReceivedButton());
}
