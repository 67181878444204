export function createAccount(user, preferences) {
    return {
        firstName: user.firstName,
        lastName: user.lastName,
        dob: user.dob,
        leadSource: user.leadSource,
        preferences: {
            menuDrawerAnchor: preferences.menuDrawerAnchor,
            menuButtonAnchor: preferences.menuButtonAnchor,
        }
    };
}
