import {Card, CardContent, FormControlLabel, Radio, RadioGroup, Grid, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import FetchPointTransactions from "../../services/pointService/FetchPointTransactions";
import PointCard from "./PointCard";

const FilterRadios = ({filter, setFilter}) => {
    const filters = ['daily_bonus', 'annual_bonus', 'special_bonus', 'annual_bonus_upgrade', 'ward_spend', 'extend_subscription', 'All'];
    return (
        <>
            <RadioGroup value={filter} onChange={(e) => setFilter(e.target.value)}>
                <Grid container direction="column" justifyContent="center" alignContent="center"
                      alignItems="center" spacing={2}>
                    {filters.map((f, i) => (
                        <Grid item key={i} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControlLabel
                                value={f}
                                control={<Radio/>}
                                label={f.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                sx={{}}
                            />
                        </Grid>
                    ))}
                </Grid>
            </RadioGroup>
        </>
    );
};

export default function PointTransactions({auth, db, pointsTransactions, setPointsTransactions, updateErrorsList}) {

    const [filter, setFilter] = useState('All');

    const loadMorePoints = FetchPointTransactions(auth, db, setPointsTransactions, filter);

    // Create a reference to the last element of the transactions list
    const observerRef = useRef();

    // Set up the intersection observer
    useEffect(() => {
        if (!observerRef.current) return;
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                // When the last element is in view, load more points
                loadMorePoints();
            }
        });
        observer.observe(observerRef.current);

        // Cleanup
        return () => observer.disconnect();
    }, [observerRef, loadMorePoints]);

    // Ensure loadMorePoints is called when the filter is updated
    useEffect(() => {
        loadMorePoints();
    }, [filter, loadMorePoints]);

    return (
        <>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" component="div" gutterBottom  align="center"
                                        fontWeight="bold" color="primary">
                                Filter Transactions By
                            </Typography>
                            <FilterRadios filter={filter} setFilter={setFilter}/>
                        </CardContent>
                    </Card>
            </Grid>
            <>
                {
                        pointsTransactions.map((point, index) => (
                            <Grid key={point.id || index} item xs={12} lg={12} md={12} sm={12} xl={12}>
                                <PointCard key={point.id || index} index={index + 3} point={point}
                                           updateErrorsList={updateErrorsList}/>
                            </Grid>
                        ))
                    }
            </>
            <div ref={observerRef}/>
        </>
    );
}
