import * as  React from 'react';
import {Grid} from "@mui/material";
import {LearnCardContentViewer} from "../services/contentService/ContentServices";

export default function Learn() {
    return (
        <Grid container direction="column" spacing={3} alignContent="center" justifyContent="center">
            <Grid item
                  sx={{marginLeft: '20%', marginRight: '20%'}}>
                <LearnCardContentViewer file="/markdown/LearnContentMenu.md"/>
            </Grid>
        </Grid>
    );
}
