export default function UpdateErrorsList(newError, errorHandling, setErrorHandling){
    const updateErrorList = () => {
        const updatedErrorsList = errorHandling.errorsList;
        updatedErrorsList.push(newError.message
            .replace('Firebase: Error (auth/', '')
            .replaceAll('-', ' ')
            .replace(').','')
            .replace('missing email', 'invalid email'));
        setErrorHandling({...errorHandling,
            errorModal: true,
            errorsList: updatedErrorsList});
        return errorHandling;
    }
    return updateErrorList()
}
