import * as React from 'react';
import {Container, Grid, Popover} from "@mui/material";

import ConfirmActionStep from "../components/ConfirmationActionStep";

export default function ConfirmationSetUpModal({
                                                   updateWardStatus,
                                                   ward,
                                                   viewModals, setViewModals,
                                                   loadingOff, loadingOn,
                                                   updateErrorsList, modalSx
                                               }) {

    const handleModalClose = () => {
        setViewModals({
            ...viewModals,
            confirmationSetupModal: false,
            confirmationSetupModalWard: null
        })
    }

    const handleSubmit = () => {
        loadingOn();
        updateWardStatus(ward.id, ward.status).then((response) => {
            loadingOff();
            if (response.response !== 'SUCCESS') {
                updateErrorsList(new Error(response.response));
            } else {
                handleModalClose();
            }
        });
    }

    const [verifyWardPage, setVerifyWardPage] = React.useState(1);
    const handleModalPage = (n) => {
        if (n < 5 && n > 0) {
            setVerifyWardPage(n);
        }
    }

    const wardModalPage = () => {
        switch (verifyWardPage) {
            case 1:
                return (<ConfirmActionStep
                    handleModalPage={handleModalPage}
                    stepTitle="Write your word phrase in the booklet"
                    stepInfo="1. Ensure you are using a Shamir Backup Word Phrase Set."
                    stepInfo2="2. Carefully write your chosen word seed in the note booklet."
                    stepInfo3="3. Fold the booklet and fix it closed using a provided tamper evident seal."
                    stepImage={process.env.PUBLIC_URL + '/KeywardWardPackWordCard.svg'}
                    onConfirm={2}
                    handleModalClose={handleModalClose}
                />)
            case 2:
                return (<ConfirmActionStep
                    handleModalPage={handleModalPage}
                    stepTitle="Package the word phrase booklet"
                    stepInfo="1. Place the word phrase booklet into the brown hardback envelope and firmly seal."
                    stepInfo2="2. Optionally, you may then double seal the envelope with a tamper evident seal."
                    stepInfo3="Note: The brown hardback envelope should have the 3 word reference, shipping reference and location marked on the face."
                    stepImage={process.env.PUBLIC_URL + '/KeywardWardPackCardInEnvelope.svg'}
                    onConfirm={3}
                    onBack={1}
                    handleModalClose={handleModalClose}
                />)
            case 3:
                return (<ConfirmActionStep
                    handleModalPage={handleModalPage}
                    stepTitle="Prepare the Ward Pack for Return"
                    stepInfo="1. Place the completed ward pack into the prepaid return envelope and seal."
                    stepImage={process.env.PUBLIC_URL + '/KeywardWardPackReturnEnvelope.svg'}
                    onConfirm={4}
                    onBack={2}
                    handleModalClose={handleModalClose}
                />)
            case 4:
                return (<ConfirmActionStep
                    handleModalPage={handleModalPage}
                    stepTitle="Send the Ward Pack in the post ASAP"
                    stepInfo="After completing the set up you should drop this in a
                    Royal Mail Postbox or deliver to a Post Office as soon as possible."
                    onSubmit={handleSubmit}
                    onBack={3}
                    handleModalClose={handleModalClose}
                />)
            default:
                return (<ConfirmActionStep
                    handleModalPage={handleModalPage}
                    stepTitle="Confirm the contents of the Ward Pack"
                    stepInfo="Ensuring you are using a Shamir Backup Word Phrase Set. Carefully write your
                    chosen 12/24 word seed in the note booklet"
                    stepImage="src/resources/WardPackItems.svg"
                    onConfirm={2}
                    handleModalClose={handleModalClose}
                />);
        }
    }

    return (
        <Popover
            key={0}
            open={viewModals.confirmationSetupModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="set up ward modal"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    {wardModalPage()}
                </Grid>
            </Container>
        </Popover>
    );
}
