import {Grid} from "@mui/material";
import {ArticleContentViewer} from "../services/contentService/ContentServices";


export default function Legal() {

    return (
            <Grid container spacing={2} alignContent="center" justifyContent="center">
                <ArticleContentViewer/>
            </Grid>
    );
}
