import { useCallback, useEffect, useState } from "react";
import { getPrices, getProducts } from "@stripe/firestore-stripe-payments";

export default function FetchProducts(payments, setProducts) {
    const [fetchedData, setFetchedData] = useState(null);

    const fetchProducts = useCallback(async () => {
        if (fetchedData) {
            setProducts(fetchedData);
            return;
        }

        try {
            const allProducts = await getProducts(payments, {
                includePrices: false,
                activeOnly: true,
            });

            const productsWithActivePrices = await Promise.all(allProducts.map(async (product) => {
                const prices = await getPrices(payments, product.id);
                const activePrices = prices.filter(price => price.active === true);

                return {
                    ...product,
                    prices: activePrices,
                };
            }));

            const subscriptionProducts = productsWithActivePrices.filter(product => product.prices.some(price => price.type === 'recurring'));
            const onetimeProducts = productsWithActivePrices.filter(product => product.prices.some(price => price.type === 'one_time'));

            const newProducts = { subscriptionProducts, onetimeProducts };
            setFetchedData(newProducts);
            setProducts(newProducts);

        } catch (error) {
            console.error('Error retrieving portal configurations:', error);
        }
    }, [payments, setProducts, fetchedData]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);
}
