export function initWardValues() {
    return {
        name: '',
        location: '',
        postageName: '',
        line1: '',
        line2: '',
        town: '',
        postcode: '',
        postageNameConf: '',
        line1Conf: '',
        line2Conf: '',
        townConf: '',
        postcodeConf: '',

        addressDisclaimer: false,
        nominatedPersonDisclaimer: false,
        wardDisclaimer: false
    }
}
