import * as React from 'react';
import {FormControl, Grid, InputLabel, OutlinedInput, Typography, Button} from "@mui/material";

export default function VerifyShippingReference({handleModalPage, handleModalClose, nextPage, values, setValues, firstWord,  updateErrorsList, style}) {

    const handleClosePage = () => {
        handleModalClose();
    }

    const handleNextPage = () => {
        handleModalPage(nextPage)
    }

    const detailsNotEntered = () => {
        return values.shippingRef === '';
    }

    const handleSetWardShippingInfo = (prop) => (event) => {
        try {
            setValues({...values, [prop]: event.target.value});
        } catch (error) {
            console.log("error handleSetWardShippingInfo", error)
            updateErrorsList(error)
        }
    };

    return (
        <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                    Enter the Ward Shipping Reference
                </Typography>
                <Typography
                            id="ship-ref-info-1"

                            align={"center"}
                            gutterBottom>
                    This can be found on the reference label of the brown hardback envelope.
                </Typography>
                <Typography
                            id="ship-ref-info-2"

                            align={"center"}
                            gutterBottom>
                    It should match the customer reference of postage label the Ward Pack arrived in.
                </Typography>
                <Typography
                            id="ship-ref-info-3"

                            align={"center"}
                            gutterBottom>
                    -
                </Typography>
                <Typography
                            id="ship-ref-info-4"

                            align={"center"}
                            gutterBottom>
                    It begins with the 1st word this Wards 3 word reference:
                </Typography>
                <Typography
                            id="ship-ref-info-5"

                            align={"center"}
                            gutterBottom>
                    {firstWord}xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                </Typography>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <FormControl id="ward-ship-ref-label" label="ward-ship=ref-label" variant="outlined"
                                 fullWidth={true}>
                        <InputLabel htmlFor="ship-ref-label">Shipping Reference</InputLabel>
                        <OutlinedInput
                            id="ward-ship-ref-label"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 40,
                                'aria-label': 'enter shipping reference'}}
                            value={values.shippingRef}
                            onChange={handleSetWardShippingInfo('shippingRef')}
                            label="Shipping Reference"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            </Grid>
            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    <Button variant="contained" id='next-confirm-receipt-button' style={style.button} onClick={handleNextPage}
                            disabled={detailsNotEntered()}>Next</Button>
                    <Button variant="contained" style={style.button} id='cancel-confirm-receipt-button'
                            onClick={handleClosePage}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
