import {httpsCallable} from "firebase/functions";

export default async function ReturnNotReceived(functions, id, status) {
    const returnNotReceived = httpsCallable(functions, 'returnNotReceived');

    const wardId = {
        id: id,
        status: status,
        newStatus: "return not received",
        action: "investigateReturn"
    };

    try {
        const result = await returnNotReceived(wardId);
        return result.data;
    } catch(error) {
        console.error(`Error in ReturnNotReceived function: ${error}`);
        return { success: false, error: error.message };
    }
}
