import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Checkbox, FormControl, FormControlLabel, FormGroup, Grid} from "@mui/material";

export default function DeleteAccountDisclaimer(state) {

    const [agree, setAgree] = React.useState(false);

    const handleConfirmSubmit = () => {
        state.handleSubmit();
    }

    const handleClosePage = () => {
        setAgree(false);
        state.handleModalClose();
    }

    const agreeDeleteDisclaimer = () => () => {
        try {
            setAgree(!agree);
        } catch (error) {
            console.log("error agreeDeleteDisclaimer", error)
            state.updateErrorsList(error)
        }
    };

    const note = () => {
        return <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                Delete My Account Disclaimer
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography id='disclaimer-note-1'

                        align={"center"}
                        gutterBottom>
                When requesting your account to be deleted you must understand that:
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography id='disclaimer-note-2'

                        align={"center"}
                        gutterBottom>
                - Any Wards with a status of Preparing, Requested, In Transit To You or
                Received By You will be moved to Ward Inactive.
            </Typography>
            <Typography
                        id='disclaimer-note-3'
                        align={"center"}
                        gutterBottom>
                - Any Wards with a status of In Transit To Us, Received By Us or Secured will be moved to
                Requested Return and processed accordingly.
            </Typography>
            <Typography
                        id='disclaimer-note-4'

                        align={"center"}
                        gutterBottom>
                - You will be permanently signed out after this action and unable to track to progress
                of any of your Wards while they are be processed and returned.
            </Typography>
            <Typography
                        id='disclaimer-note-5'

                        align={"center"}
                        gutterBottom>
                - Your account will be processed for deletion, any active subscriptions will be cancelled
                and you will no longer be able to login.
            </Typography>
            <Typography
                        id='disclaimer-note-6'

                        align={"center"}
                        gutterBottom>
                - Your data will be deleted after both 90 days have passed
                and all Wards have been moved into Ward Inactive
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <FormControl>
                <FormGroup>
                    <FormControlLabel id="disclaimer-checkbox" control={<Checkbox/>}
                                      checked={agree}
                                      onChange={agreeDeleteDisclaimer()}
                                      label="By ticking this box I confirm I have read and understood the above"/>
                </FormGroup>
            </FormControl>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    <Button variant="contained"
                            id='submit-delete-account'
                            onClick={handleConfirmSubmit}
                            disabled={!agree}>Submit</Button>
                    <Button variant="contained"
                            id='cancel-delete-account'
                            onClick={handleClosePage}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }

    return note();
}
