import { httpsCallable } from "firebase/functions";

function sanitizeString(str) {
    return str.toLowerCase().trim();
}

export default function VerifyWardReceived(functions, ward, values) {
    const verifyWard = httpsCallable(functions, 'verifyReceivedWard');
    const verify = {
        id: ward.id,
        status: ward.status,
        reference: ward.reference,
        shippingRef: sanitizeString(values.shippingRef),
        shippingBusinessName: sanitizeString(values.shippingBusinessName),
        shippingLine1: sanitizeString(values.shippingLine1),
        shippingLine2: sanitizeString(values.shippingLine2),
        shippingTown: sanitizeString(values.shippingTown),
        shippingPostcode: sanitizeString(values.shippingPostcode)
    }

    return verifyWard(verify)
        .then((result) => {
            const data = result.data;
            return {
                response: data.response
            };
        })
        .catch((error) => {
            console.error(`Error verifying ward received: ${error}`);
            // Return or throw error, based on your error handling strategy
        });
}
