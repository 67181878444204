import {Grid} from "@mui/material";
import {CardContentViewer} from "../services/contentService/ContentServices";


export default function TheStory() {

    return (
            <Grid container spacing={2} alignContent="center" justifyContent="center">
                <CardContentViewer file="/markdown/TheStoryContent.md"/>
            </Grid>
    );
}
