import * as React from 'react';
import {Container, Grid, Popover} from "@mui/material";


import ConfirmationDisclaimer from "../components/disclaimers/ConfirmationDisclaimer";
import DeleteAccountDisclaimer from "../components/disclaimers/DeleteAccountDisclaimer";

export default function DeleteAccountModal({
                                               deleteAccount, signOutOfAccount,
                                               viewModals, setViewModals, loadingOn, loadingOff,
                                               updateErrorsList, modalSx
                                           }) {

    const [page, setPage] = React.useState(1);

    const handleModalClose = () => {
        try {
            setViewModals({
                ...viewModals,
                deleteAccountModal: false,
            });
            return true
        } catch (error) {
            console.log("error handleDeleteAccountModal close", error)
            updateErrorsList(error)
        }
        return false
    }

    const handleSubmit = () => {
        try {
            loadingOn();
            deleteAccount().then(async (response) => {
                const success = await response;
                if (success.data.response !== "SUCCESS") {
                    handleModalClose()
                    updateErrorsList(new Error("failed to delete account"));
                } else {
                    signOutOfAccount().then(() => {
                        setViewModals({
                            ...viewModals,
                            deleteAccountModal: false,
                            messageModal: true,
                            modalMessage: ['Your Account is being processed for deletion']
                        });
                        loadingOff();
                        setPage(1);
                    })
                }
            });
        } catch (error) {
            console.log("error handleDeleteAccountModal submit", error)
            updateErrorsList(error)
        }
    }

    // 1 ConfirmationDisclaimer
    // 2 DeleteAccountDisclaimer
    const handleModalPage = (n) => {
        if (n === 1 || n === 2) {
                setPage(n);
        }
    }

    const deleteAccountModalPage = () => {
        switch (page) {
            case 1:
                return (<ConfirmationDisclaimer

                    title={"Confirm Account Deletion"}
                    note1={"This action CAN NOT be undone"}
                    nextPage={2}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>);
            case 2:
                return (<DeleteAccountDisclaimer

                    updateErrorsList={updateErrorsList}
                    handleSubmit={handleSubmit}
                    handleModalClose={handleModalClose}/>);
            default:
                return (<ConfirmationDisclaimer

                    title={"Confirm Account Deletion"}
                    note1={"This action CAN NOT be undone"}
                    nextPage={2}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>);
        }
    }

    return (
        <Popover
            key={0}
            open={viewModals.deleteAccountModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="delete your account modal"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    {deleteAccountModalPage()}
                </Grid>
            </Container>
        </Popover>
    );
}
