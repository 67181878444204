import {Card, CardContent, Grid} from "@mui/material";
import ReactMarkdown from "react-markdown";

export const ExpandedCard = ({ expandedDescription }) => {
    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
            <Card style={{
                marginTop: '16px',
                padding: '16px',
                backgroundColor: '#f9f9f9',
            }}>
                <CardContent style={{
                    width: '100%',
                    textAlign: 'left',
                }}>
                    <ReactMarkdown>{expandedDescription}</ReactMarkdown>
                </CardContent>
            </Card>
        </Grid>
    );
};

