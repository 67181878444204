import * as React from 'react';
import {Grid, Button, Container, Popover} from "@mui/material";

import Typography from "@mui/material/Typography";

export default function RequestReturnModal({
                                               updateWardStatus,
                                               ward,
                                               viewModals, setViewModals,
                                               loadingOff, loadingOn,
                                               updateErrorsList, modalSx
                                           }) {

    const handleClose = () => {
        setViewModals({
            ...viewModals,
            returnRequestedModal: false,
            returnRequestedModalWard: null
        })
    }

    const handleSubmit = () => {
        loadingOn();
        updateWardStatus(ward.id, ward.status).then((response) => {
            loadingOff();
            if (response.response !== 'SUCCESS') {
                updateErrorsList(new Error(response.response));
            } else {
                handleClose();
            }
        });
    }

    return (
        <Popover
            key={0}
            open={viewModals.returnRequestedModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="request return of ward modal"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                        <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                            <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                                Confirm that you want to request the return of this ward?
                            </Typography>
                            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                                    <Button variant="contained" id="confirm-return-button"
                                            onClick={handleSubmit}>
                                        Confirm</Button>
                                    <Button variant="contained" id="cancel-return-button"
                                            onClick={handleClose}>Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Popover>
    );
}
