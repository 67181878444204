import React from 'react';
import { Card, CardContent, Button, ButtonGroup } from '@mui/material';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

const OrganiseCard = ({ setSortBy, updateErrorsList }) => {
    const [sort, setSort] = React.useState('name');
    const [sortOrder, setSortOrder] = React.useState('asc');

    const handleSortChange = (value) => {
        if (value === sort) {
            const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
            setSortOrder(newSortOrder);
            setSortBy({sort: value, sortOrder: newSortOrder});
        }
        else {
            setSortOrder('asc');
            setSort(value);
            setSortBy({sort: value, sortOrder: 'asc'});
        }
    };

    return (
        <Card>
                <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="h6" gutterBottom>Sort by</Typography>
                        <ButtonGroup  aria-label="outlined primary button group">
                            <Button onClick={() => handleSortChange('name')} variant={sort === 'name' ? "contained" : "outlined"}>
                                Name {sort === 'name' && (sortOrder === 'asc' ?  <ArrowDownward /> : <ArrowUpward /> )}
                            </Button>
                            <Button onClick={() => handleSortChange('location')} variant={sort === 'location' ? "contained" : "outlined"}>
                                Location {sort === 'location' && (sortOrder === 'asc' ? <ArrowDownward /> : <ArrowUpward /> )}
                            </Button>
                            <Button onClick={() => handleSortChange('status')} variant={sort === 'status' ? "contained" : "outlined"}>
                                Status {sort === 'status' && (sortOrder === 'asc' ? <ArrowDownward /> : <ArrowUpward /> )}
                            </Button>
                        </ButtonGroup>
                    </Box>
                </CardContent>
            </Card>
    );
}

export default OrganiseCard;
