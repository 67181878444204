import {Button, Grid} from "@mui/material";
import * as React from 'react';
import Divider from "@mui/material/Divider";

export default function CompleteSetup(state) {

    const completeSetupButton = () => {
        if (state.ward.status === "received by you") {
            return <Grid container
                         alignItems="center"
                         direction="column"
                         spacing={2}>
                <Divider orientation="horizontal" flexItem style={{margin: 6}}/>
                <Button variant="contained" id="complete-setup"
                        onClick={e => state.handleDisplayConfirmationSetupModal(state.ward.id,true)}>
                    Complete Setup
                </Button>
            </Grid>
        }
    }

    return (completeSetupButton());
}
