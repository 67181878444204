export function initViewModals() {
    return {
        messageModal: false,
        modalMessage: [],

        signInModal: false,
        signUpModal: false,
        reauthenticateModal: false,
        forgotPasswordModal: false,
        deleteAccountModal: false,

        newWardModal: false,

        verifyReceivedModal: false,
        verifyReceivedModalWard: null,

        confirmationSetupModal: false,
        confirmationSetupModalWard: null,

        notReceivedModal: false,
        notReceivedModalWard: null,

        requestReplacementModal: false,
        requestReplacementModalWard: null,

        returnRequestedModal: false,
        returnRequestedModalWard: null,

        returnNotReceivedModal: false,
        returnNotReceivedModalWard: null,

        genericConfirmationModal: false,
        genericConfirmationMessage: [],
        genericConfirmationFunction: null,

    }
}
