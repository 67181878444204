export function newWard(name, location) {
        return {
                name: name,
                location: location,
        };
};

export function createWard(id, name, location, reference, status, created, lastModified) {
        return {
                id: id,
                name: name,
                location: location,
                reference: reference,
                status: status,
                created: created,
                lastModified: lastModified,
        };
};

export function createWardEntity(id, wardData) {
        return {
                displayDetailsPanel: false,
                displayReturnRequestedModal: false,
                editName: false,
                enteredEditName: '',
                ward: createWard(id, wardData.name, wardData.location,
                    wardData.reference, wardData.status, wardData.created, wardData.lastModified)
        };
}

export function createWardEntityWithName(id, wardData, name) {
        return {
                displayDetailsPanel: false,
                displayReturnRequestedModal: false,
                editName: false,
                enteredEditName: '',
                ward: createWard(id, name, wardData.location,
                    wardData.reference, wardData.status, wardData.created, wardData.lastModified)
        };
}


export function wardToJSON(ward) {
        return {
                name: ward.name,
                location: ward.location,
                reference: ward.reference,
                status: ward.status,
                created: ward.created,
                lastModified: ward.lastModified,
        }
};
