import {Grid} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {CardContentViewer} from "../services/contentService/ContentServices";
import SubscriptionTableItems from "../components/billingComponents/SubscriptionTableItems";


export default function Home({
                                 currentUser,
                                 pointBalance,
                                 products,
                                 payments,
                                 subscription,
                                 extendSubscription,
                                 viewModals,
                                 setViewModals,
                                 loadingOn,
                                 updateErrorsList
                             }) {

    const theme = useTheme();
    const isLgOrXl = useMediaQuery(theme.breakpoints.up('md'));
    const productDirection = isLgOrXl ? 'row' : 'column';

    return (<Grid container spacing={2} alignContent="center" justifyContent="center">
            {(!subscription && currentUser &&
                <Grid item sx={{marginLeft: '10%', marginRight: '10%'}}>
                    <SubscriptionTableItems
                        products={products}
                        payments={payments}
                        loadingOn={loadingOn}
                        updateErrorsList={updateErrorsList}
                        productDirection={productDirection}/>
                </Grid>)}
                <CardContentViewer file="/markdown/HomeContent.md"/>
        </Grid>
    );
}
