import * as React from 'react';
import SignUpModalUserDetails from "./SignUpModalUserDetails";
import SignUpModalEmailDetails from "./SignUpModalEmailDetails";
import {Container, Grid, Popover} from "@mui/material";


export default function SignUpModal({
                                        viewModals,
                                        setViewModals,
                                        createAccount,
                                        loadingOn,
                                        loadingOff,
                                        updateErrorsList,
                                        modalSx
                                    }) {
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        dob: null,
        leadSource: '-None-',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false
    });

    const signUp = () => {
        try {
            loadingOn();
            createAccount(values, setValues)
        } catch (error) {
            loadingOff();
            console.log("error signUp", error)
            updateErrorsList(error)
        }
    }

    const handleSignUpModalChange = (prop) => (event) => {
        try {
            setValues({...values, [prop]: event.target.value});
        } catch (error) {
            console.log("error handleSignUpModalChange", error)
            updateErrorsList(error)
        }
    };

    const handleClickShowPassword = () => {
        try {
            setValues({
                ...values,
                showPassword: !values.showPassword,
            });
        } catch (error) {
            console.log("error handleClickShowPassword", error)
            updateErrorsList(error)
        }
    };

    const handleClickShowConfirmPassword = () => {
        try {
            setValues({
                ...values,
                showConfirmPassword: !values.showConfirmPassword,
            });
        } catch (error) {
            console.log("error handleClickShowConfirmPassword", error)
            updateErrorsList(error)
        }
    };

    const handleMouseDownPassword = (event) => {
        try {
            event.preventDefault();
        } catch (error) {
            console.log("error handleMouseDownPassword", error)
            updateErrorsList(error)
        }
    };

    const handleSignUpModalClose = () => {
        setViewModals({
            ...viewModals,
            signInModal: false,
            signUpModal: false
        });
        setSignUpPage(1);
        setValues({
            firstName: '',
            lastName: '',
            dob: null,
            leadSource: '-None-',
            email: '',
            confirmEmail: '',
            password: '',
            confirmPassword: '',
            showPassword: false,
            showConfirmPassword: false
        })
    }

    const handleDobChange = (newValue) => {
        try {
            if (newValue != null) {
                let dte = new Date(newValue.getFullYear(), newValue.getMonth(), newValue.getDate(),
                    12, 0, 0, 0)
                setValues({...values, 'dob': dte});
            } else {
                setValues({...values, 'dob': null});
            }
        } catch (error) {
            console.log("error handleSignUpModalChange", error)
            updateErrorsList(error)
        }
    };

    const [signUpPage, setSignUpPage] = React.useState(1);
    // 1 SignUpModalUserDetails
    // 2 SignUpModalEmailDetails
    const handleSignUpModalWardPage = (n) => {
        if (n < 3 && n > 0) {
            if (n === 1) {
                setSignUpPage(n);
            } else if (n === 2) {
                setSignUpPage(n);
            }
        }
    }

    const signUpModalPage = () => {
        switch (signUpPage) {
            case 1:
                return (<SignUpModalUserDetails
                    values={values}
                    handleSignUpModalChange={handleSignUpModalChange}
                    handleDobChange={handleDobChange}
                    handleSignUpModalWardPage={handleSignUpModalWardPage}
                    handleSignUpModalClose={handleSignUpModalClose}/>);
            case 2:
                return (<SignUpModalEmailDetails
                    values={values}
                    handleSignUpModalChange={handleSignUpModalChange}
                    handleClickShowPassword={handleClickShowPassword}
                    handleMouseDownPassword={handleMouseDownPassword}
                    handleClickShowConfirmPassword={handleClickShowConfirmPassword}
                    handleSignUpModalWardPage={handleSignUpModalWardPage}
                    signUp={signUp}
                    handleSignUpModalClose={handleSignUpModalClose}/>);
            default:
                return (<SignUpModalUserDetails
                    values={values}
                    handleSignUpModalChange={handleSignUpModalChange}
                    handleDobChange={handleDobChange}
                    handleSignUpModalWardPage={handleSignUpModalWardPage}
                    handleSignUpModalClose={handleSignUpModalClose}/>);
        }
    }

    return (
        <Popover
            open={viewModals.signUpModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleSignUpModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="sign up modal"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    {signUpModalPage()}
                </Grid>
            </Container>
        </Popover>
    );
}
