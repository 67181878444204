import * as React from 'react';
import {Container, Grid, Popover} from "@mui/material";


import AddressCheck from "../../functions/AddressCheck";
import RegexLengthCheck from "../../functions/RegexLengthCheck";

import AddressDisclaimer from "../components/disclaimers/AddressDisclaimer";
import NominatedPersonDisclaimer from "../components/disclaimers/NominatedPersonDisclaimer";
import EnterAddress from "../components/address/EnterAddress";
import EnterAddressConfirmation from "../components/address/EnterAddressConfirmation";
import WardReview from "../components/WardReview";
import DoesStringNotEqual from "../../functions/DoesStringNotEqual";
import EnterWardDetails from "../components/EnterWardDetails";
import ConfirmationDisclaimer from "../components/disclaimers/ConfirmationDisclaimer";
import WordSeedDisclaimer from "../components/disclaimers/WordSeedDisclaimer";
import {initWardValues} from "../../factories/InitWardValuesFactory";

export default function NotReceivedWardModal({
                                                 requestReplacementWard,
                                                 ward,
                                                 viewModals, setViewModals,
                                                 loadingOff, loadingOn,
                                                 updateErrorsList, modalSx
                                             }) {

    const [page, setPage] = React.useState(1);
    const [values, setValues] = React.useState(initWardValues());

    const handleCheckAddress = () => {
        return AddressCheck(values, updateErrorsList);
    }

    const handleModalClose = () => {
        try {
            setViewModals({
                ...viewModals,
                notReceivedModal: false,
                notReceivedModalWard: null,
            });
            loadingOff();
            setPage(1);
            setValues(initWardValues());
        } catch (error) {
            console.log("error handleNewWardModalClose", error)
            updateErrorsList(error)
        }
    }

    const handleSubmit = () => {
        loadingOn();
        try {
            if (RegexLengthCheck(values.name, "Ward Name", 12)) {
                if (DoesStringNotEqual(values.location, '', "You must include a Ward Location")) {
                    if (RegexLengthCheck(values.postageName, "Postage Name", 64)) {
                        if (RegexLengthCheck(values.line1, "Address Line 1", 64)) {
                            if (RegexLengthCheck(values.town, "Town", 64)) {
                                if (RegexLengthCheck(values.postcode, "Post Code", 7)) {
                                    requestReplacementWard(ward, values, "notReceived")
                                        .then(async (res) => {
                                            if (res && res.data && res.data.status && res.data.status !== 200) {
                                                handleModalClose()
                                                updateErrorsList(new Error(res.data.response));
                                            } else {
                                                handleModalClose();
                                            }
                                        });
                                }
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.log("error handleNewWardModal", error)
            updateErrorsList(error)
        }
    }

    // 1 NotReceivedWardDisclaimer
    // 2 EnterWardDetails
    // 3 WardNote1
    // 4 WardNote2
    // 5 EnterAddress
    // 6 EnterAddressConfirmation
    // 7 WardReview
    // 8 WardNote3
    const handleModalPage = (n) => {
        if (n < 9 && n > 0) {
            if (n <= 3) {
                setPage(n);
            } else if (n <= 4 && values.addressDisclaimer) {
                setPage(n);
            } else if (n <= 6 && values.nominatedPersonDisclaimer && values.addressDisclaimer) {
                setPage(n);
            } else if (n <= 8 && handleCheckAddress() && values.nominatedPersonDisclaimer && values.addressDisclaimer) {
                setPage(n);
            }
        }
    }

    const wardModalPage = () => {
        switch (page) {
            case 1:
                return (<ConfirmationDisclaimer

                    title={"Confirm that you have not received your initial Ward Pack"}
                    note1={"This ward will become inactive and a new ward pack will be issued."}
                    nextPage={2}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>);
            case 2:
                return (<EnterWardDetails

                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={1}
                    nextPage={3}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>);
            case 3:
                return <AddressDisclaimer

                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={2}
                    nextPage={4}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
            case 4:
                return <NominatedPersonDisclaimer

                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={3}
                    nextPage={5}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
            case 5:
                return <EnterAddress

                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={4}
                    nextPage={6}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
            case 6:
                return <EnterAddressConfirmation

                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={5}
                    nextPage={7}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
            case 7:
                return <WardReview

                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={6}
                    nextPage={8}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
            case 8:
                return <WordSeedDisclaimer

                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    handleSubmit={handleSubmit}
                    handleModalClose={handleModalClose}/>;
            default:
                return (<ConfirmationDisclaimer

                    title={"Confirm that you have not received your initial Ward Pack"}
                    note1={"This ward will become inactive and a new ward pack will be issued."}
                    nextPage={2}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>);
        }
    }

    return (
        <Popover
            key={0}
            open={viewModals.notReceivedModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="not received initial ward pack modal"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    {wardModalPage()}
                </Grid>
            </Container>
        </Popover>
    );
}
