import {initViewModals} from "../factories/InitViewModalsFactory";


export default function Welcome(user, name, setViewModals) {
    const setWelcome = (user) => {
        return user.emailVerified
    }

    const initModal = initViewModals();
    if (setWelcome(user)) {
        setViewModals({...initModal,
            messageModal: true,
            modalMessage: ['Hello, ' + name,
                'Welcome to KeyWard.com']
        })
    }

}
