import {
    Card, Grid, FormControl, FormLabel, InputLabel, OutlinedInput, Collapse
} from "@mui/material";
import Button from "@mui/material/Button";
import DoesStringEqual from "../../functions/DoesStringEqual";

import ChangeAuthEmail from "../../services/authService/ChangeAuthEmail";
import {useState} from "react";

export default function MyEmailCard({
                                        auth, currentUser, accountData, setAccountData, redactedEmail,
                                        loadingOn, loadingOff,
                                        viewModals, setViewModals, functions, updateErrorsList
                                    }) {


    const [tmpEmail, setTmpEmail] = useState("");
    const [tmpEmail2, setTmpEmail2] = useState("");

    const [change, setChange] = useState(false);

    const handleCancelEmailChange = (event) => {
        try {
            setTmpEmail("");
            setTmpEmail2("");
            setChange(false);
        } catch (error) {
            console.log("error handleCancelEmailChange", error)
            updateErrorsList(error)
        }
    }

    const handleTypeEmail = (event) => {
        try {
            setTmpEmail(event.target.value)
        } catch (error) {
            console.log("error handleTypeEmail", error)
            updateErrorsList(error)
        }
    }

    const handleTypeEmail2 = (event) => {
        try {
            setTmpEmail2(event.target.value)
        } catch (error) {
            console.log("error handleTypeEmail2", error)
            updateErrorsList(error)
        }
    }

    const handeShowReauthenticateModal = () => {
        setViewModals({
            ...viewModals,
            reauthenticateModal: true
        });
    }
    const handleMessageModalOpen = () => {
        setViewModals({
            ...viewModals,
            messageModal: true,
            modalMessage: ['You have been signed out', 'A verification link has been sent to your new email address',
                'You must verify your email before you can sign back in']
        });
    }

    const handleEmailChange = async () => {
        try {
            loadingOn();
            DoesStringEqual(tmpEmail, tmpEmail2, "Emails do not match")

            const response = await ChangeAuthEmail(auth,
                accountData, setAccountData, tmpEmail,
                setTmpEmail, functions, handeShowReauthenticateModal,
                handleMessageModalOpen, updateErrorsList);

            if (response) {
                handleCancelEmailChange()
            }
        } catch (error) {
            console.log(`Error in handleEmailChange: ${error}`);
            updateErrorsList(error);
        } finally {
            loadingOff();
        }
    };

    const handleEmailClick = () => {
        setChange(true)
    }

    const displayChange = () => {
        return (
            <Grid container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justify="center">
                <FormControl>
                    <Grid><br/></Grid>
                    <Grid textAlign="center">
                        <FormLabel id="demo-controlled-radio-buttons-group">
                            Email: {redactedEmail}
                        </FormLabel>
                    </Grid>
                    <Grid><br/></Grid>
                    <Collapse in={change}>
                        <Grid containerspacing={2}>
                            <Grid><br/></Grid>
                            <Grid item>
                                <FormControl id="outlined-basic" label="Email" variant="outlined"
                                             fullWidth={true}>
                                    <InputLabel htmlFor="email">New Email</InputLabel>
                                    <OutlinedInput
                                        id="email-input"
                                        onChange={handleTypeEmail}
                                        value={tmpEmail}
                                        inputProps={{maxLength: 64, 'aria-label': 'new email input'}}
                                        label="Email"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid><br/></Grid>
                        </Grid>
                        <Grid containerspacing={2}>
                            <Grid><br/></Grid>
                            <Grid item>
                                <FormControl id="outlined-basic" label="Confirm Email" variant="outlined"
                                             fullWidth={true}>
                                    <InputLabel htmlFor="confirm-email">Confirm New
                                        Email</InputLabel>
                                    <OutlinedInput
                                        id="confirm-email-input"
                                        onChange={handleTypeEmail2}
                                        value={tmpEmail2}
                                        inputProps={{maxLength: 64, 'aria-label': 'confirm new email input'}}
                                        label="Email"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid><br/></Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                                <Button variant="contained" onClick={handleEmailChange}>Save</Button>
                            </Grid>
                            <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                                <Button variant="contained" onClick={handleCancelEmailChange}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Collapse>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                            {!change && (<Button variant="contained" onClick={handleEmailClick}>
                                {change ? "Cancel" : "Update Email"}
                            </Button>)}
                        </Grid>
                    </Grid>
                    <Grid><br/></Grid>
                </FormControl>
            </Grid>
        );
    }

    if (!currentUser) {
        return null;
    }

    return (
            <Card key="myEmailForm">
                {displayChange()}
            </Card>
    );
}
