import { sendEmailVerification, signOut, updateEmail } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import {initAccount} from "../../factories/InitAccountFactory";
import RegexEmailCheck from "../../functions/RegexEmailCheck";

export default async function ChangeAuthEmail(auth, accountDetails, setAccountDetails,
                                              tmpEmail, setTmpEmail, functions,
                                              handeShowReauthenticateModal, handleMessageModalOpen, updateErrorList) {

    const requestEmailUpdateSync = async () => {
        const func = httpsCallable(functions, 'requireSyncEmail');
        await func();
    }

    try {
        if (!RegexEmailCheck(tmpEmail, "New Email")) {
            throw new Error("Invalid new email");
        }
        await updateEmail(auth.currentUser, tmpEmail);
        await requestEmailUpdateSync();
        await sendEmailVerification(auth.currentUser);
        await signOut(auth);
        handleMessageModalOpen();
        setAccountDetails(initAccount());

    } catch (error) {
        if (error.code === ("auth/requires-recent-login")) {
            handeShowReauthenticateModal();
        } else {
            updateErrorList(error);
        }
    }
}
