import React, {useState} from 'react';
import {Grid} from "@mui/material";
import MySubscriptionCard from "../components/myAccountComponents/MySubscriptionCard";
import SubscriptionExtensionBox from "../components/billingComponents/SubscriptionExtensionBox";
import MyDetailsGroup from "../components/myAccountComponents/MyDetailsGroup";
import AccessibilityOptionsGroup from "../components/myAccountComponents/AccessibilityOptionsGroup";
import SubscriptionTableItems from "../components/billingComponents/SubscriptionTableItems";

export default function MyAccount(props) {
    const {
        auth, products, payments, pointBalance, extendSubscription,
        currentUser, redactedEmail, accountData,
        setAccountData, subscription, viewModals, setViewModals,
        functions, updateAccountPreferences, loadingOn, loadingOff, updateErrorsList
    } = props;

    const [isExtensionBoxVisible, setExtensionBoxVisible] = useState(false);

    return (
        <Grid container direction="column" spacing={3} alignContent="center" justifyContent="center">
            <Grid item
                  sx={{marginLeft: '15%', marginRight: '15%'}}>
                {
                    !subscription && auth.currentUser
                        ? <SubscriptionTableItems {...{products, payments, loadingOn, updateErrorsList}} />
                        : <MySubscriptionCard {...{
                            currentUser,
                            subscription,
                            pointBalance,
                            extendSubscription,
                            viewModals,
                            setViewModals,
                            functions,
                            loadingOn,
                            isExtensionBoxVisible,
                            setExtensionBoxVisible,
                            updateErrorsList
                        }} />
                }
            </Grid>

            <Grid item
                  sx={{marginLeft: '15%', marginRight: '15%'}}>
                {isExtensionBoxVisible && <SubscriptionExtensionBox {...{
                    subscription,
                    pointBalance,
                    extendSubscription,
                    viewModals,
                    setViewModals,
                    updateErrorsList
                }} />}
            </Grid>

                {
                    auth.currentUser
                    &&   <Grid item
                               sx={{marginLeft: '15%', marginRight: '15%'}}>
                        <MyDetailsGroup {...{
                            auth,
                            currentUser,
                            accountData,
                            setAccountData,
                            viewModals,
                            setViewModals,
                            redactedEmail,
                            loadingOn,
                            loadingOff,
                            functions,
                            updateErrorsList
                        }} />
                    </Grid>
                }
                <Grid item
                      sx={{marginLeft: '15%', marginRight: '15%'}}>
                    <AccessibilityOptionsGroup {...{
                        currentUser,
                        accountData,
                        setAccountData,
                        updateAccountPreferences,
                        updateErrorsList
                    }}/>
                </Grid>
        </Grid>
    );
}
