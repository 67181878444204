import {Button, Grid} from "@mui/material";
import * as React from 'react';
import Divider from "@mui/material/Divider";

export default function RequestReturn(state) {

    const requestReturnButton = () => {
        if (state.ward.status === "secured") {
            return <Grid container
                         alignItems="center"
                         direction="column"
                         spacing={2}>
                <Divider orientation="horizontal" flexItem style={{margin: 6}}/>
                <Button variant="contained" id="request-return"
                        onClick={e => state.handleDisplayReturnRequestedModal(state.ward.id,true)}>
                    Request Return</Button>
            </Grid>
        }
    }
    return (requestReturnButton());
}
