import {doc, updateDoc} from "firebase/firestore";
import {updatePreferences} from "../../factories/PreferencesFactory";


export default function UpdateAccountPreferences(auth, db, functions, account) {
    const updateAccountDetails = async () => {
        if (!auth.currentUser) return;
        const ref = doc(db, "users", auth.currentUser.uid);
        return updateDoc(ref, updatePreferences(account.preferences.menuDrawerAnchor, account.preferences.menuButtonAnchor));
        }
    updateAccountDetails();
}
