import {Backdrop} from "@mui/material";
import * as React from "react";

export default function Loading(state){
    const PadlockSpinner = () => {
        return (
            <div className="padlock-spinner" style={{height: 200, width: 200}}>
                <object
                    type="image/svg+xml"
                    data={`${process.env.PUBLIC_URL}/loader-logo.svg`}
                    aria-label="KeyWard.com logo"
                ></object>
            </div>
        );
    }


    const loading = () => {
        return (
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                    open={state.load}
                >
                    {PadlockSpinner()}
                </Backdrop>
            </div>
        );
    }
    return loading();
}
