import * as React from 'react';
import { Grid, Typography, Button } from "@mui/material";
import Box from "@mui/material/Box";

export default function ConfirmActionStep({ handleModalPage, stepTitle, stepInfo, stepInfo2, stepInfo3,
                                              stepImage, stepIssue, onConfirm, onBack, onSubmit, handleModalClose } ) {
    const handleBackPage = () => {
        handleModalPage(onBack)
    }

    const handleNextPage = () => {
        handleModalPage(onConfirm)
    }

    const handleSubmit = () => {
        onSubmit()
    }

    return (
        <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                <Typography id="modal-title" variant="h6" component="div" textAlign="center">
                    {stepTitle}
                </Typography>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                <Typography id="step-info"
                            align={"left"}
                            gutterBottom>
                    {stepInfo}
                </Typography>
                {stepInfo2 && (<Typography id="step-info2"

                                           align={"left"}
                                           gutterBottom>
                    {stepInfo2}
                </Typography>)}
                {stepInfo3 && (<Typography id="step-info3"

                                           align={"left"}
                                           gutterBottom>
                    {stepInfo3}
                </Typography>)}
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                {stepImage && (<Box component="div" sx={{ height: "80%", width: "80%", display: "flex",
                    justifyContent: "center", alignItems: "center" }}>
                    <img src={stepImage} alt={stepTitle} style={{ maxHeight: "70%", maxWidth: "70%" }}/>
                </Box>)}
                {stepIssue && (<Typography id="step-info"

                            align={"center"}
                            component="div"
                            gutterBottom>
                    {stepIssue}
                </Typography>)}
            </Grid>
            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    {onSubmit && (
                        <Button variant="contained" id='confirm-button' onClick={handleSubmit}>Confirm</Button>)}
                    {onConfirm && (
                    <Button variant="contained" id='confirm-button' onClick={handleNextPage}>Confirm</Button>)}
                    {onBack && (
                    <Button variant="contained" id='back-button' onClick={handleBackPage}>Back</Button>)}
                    <Button variant="contained" id='cancel-button' onClick={handleModalClose}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
