import MessageModal from "./menuModals/MessageModal";
import SignInModal from "./menuModals/SignInModal";
import SignUpModal from "./menuModals/signUpModal/SignUpModal";
import * as React from "react";
import ErrorModal from "./menuModals/ErrorModal";
import ReauthenticateModal from "./menuModals/ReauthenticateModal";
import ForgotPasswordModal from "./menuModals/ForgotPasswordModal";
import NewWardModal from "./wardModals/NewWardModal";
import VerifyWardModal from "./wardModals/VerifyWardModal";
import RequestReturnModal from "./wardModals/RequestReturnModal";
import NotReceivedWardModal from "./wardModals/NotReceivedWardModal";
import RequestReplacementWardModal from "./wardModals/RequestReplacementWardModal";
import DeleteAccountModal from "./menuModals/DeleteAccountModal";
import ReturnNotReceivedWardModal from "./wardModals/ReturnNotReceivedWardModal";
import ConfirmationSetUpModal from "./wardModals/ConfirmationSetUpModal";
import GenericConfirmationModal from "./menuModals/GenericConfirmationModal";

export default function DisplayModals(createAccount, deleteAccount,
                                      signInToAccount, signOutOfAccount,
                                      reauthenticateAccount,
                                      forgotPassword, wardDetails,
                                      createNewWard, requestReplacementWard,
                                      updateWardStatus, verifyWardReceived,
                                      returnNotReceived,
                                      viewModals, setViewModals,
                                      errorHandling, setErrorHandling,
                                      isLoading, loadingOn, loadingOff, updateErrorsList) {

    const displayMessageModal = () => {
        try {
            return <MessageModal viewModals={viewModals} setViewModals={setViewModals} />
        } catch (error) {
            console.log("error displayMessageModal", error)
            updateErrorsList(error)
        }
    }

    const displaySignUpModal = () => {
        try {
            return <SignUpModal createAccount={createAccount}
                                viewModals={viewModals} setViewModals={setViewModals}
                                isLoading={isLoading} loadingOn={loadingOn} loadingOff={loadingOff}
                                updateErrorsList={updateErrorsList}
            />;
        } catch (error) {
            console.log("error displaySignUpModal", error)
            updateErrorsList(error)
        }
    }

    const displayDeleteAccountModal = () => {
        try {
            return <DeleteAccountModal deleteAccount={deleteAccount} signOutOfAccount={signOutOfAccount}
                                       viewModals={viewModals} setViewModals={setViewModals}
                                       loadingOn={loadingOn} loadingOff={loadingOff}
                                       updateErrorsList={updateErrorsList}
            />;
        } catch (error) {
            console.log("error displayDeleteAccountModal", error)
            updateErrorsList(error)
        }
    }

    const displaySignInModal = () => {
        try {
            return < SignInModal signInToAccount={signInToAccount}
                                 viewModals={viewModals} setViewModals={setViewModals}
                                 loadingOn={loadingOn} loadingOff={loadingOff}
                                 updateErrorsList={updateErrorsList} />;
        } catch (error) {
            console.log("error displaySignInModal", error)
            updateErrorsList(error)
        }
    }

    const displayReauthenticateModal = () => {
        try {
            return <ReauthenticateModal reauthenticateAccount={reauthenticateAccount}
                                        viewModals={viewModals} setViewModals={setViewModals}
                                        loadingOn={loadingOn} loadingOff={loadingOff}
                                        updateErrorsList={updateErrorsList} />;
        } catch (error) {
            console.log("error displayReauthenticateModal", error)
            updateErrorsList(error)
        }
    }

    const displayForgotPasswordModal = () => {
        try {
            return <ForgotPasswordModal forgotPassword={forgotPassword}
                                        viewModals={viewModals}
                                        setViewModals={setViewModals}
                                        loadingOn={loadingOn} loadingOff={loadingOff}
                                        updateErrorsList={updateErrorsList} />;
        } catch (error) {
            console.log("error displayForgotPasswordModal", error)
            updateErrorsList(error)
        }
    }

    const displayNewWardModal = () => {
        try {
            return <NewWardModal createNewWard={createNewWard}
                                 viewModals={viewModals} setViewModals={setViewModals}
                                 loadingOn={loadingOn} loadingOff={loadingOff}
                                 updateErrorsList={updateErrorsList} />
        } catch (error) {
            console.log("error displayNewWardModal", error)
            updateErrorsList(error)
        }
    }

    const displayReceivedWardModal = () => {
        if (viewModals.verifyReceivedModalWard !== null) {
            try {
                return <VerifyWardModal verifyWardReceived={verifyWardReceived}
                                        ward={wardDetails.get(viewModals.verifyReceivedModalWard).ward}
                                        viewModals={viewModals} setViewModals={setViewModals}
                                        loadingOn={loadingOn} loadingOff={loadingOff}
                                        updateErrorsList={updateErrorsList} />
            } catch (error) {
                console.log("error displayReceivedWardModal", error)
                updateErrorsList(error)
            }
        }
    }

    const displayConfirmationSetupModal = () => {
        if (viewModals.confirmationSetupModalWard !== null) {
            try {
                return <ConfirmationSetUpModal updateWardStatus={updateWardStatus}
                                               ward={wardDetails.get(viewModals.confirmationSetupModalWard).ward}
                                               viewModals={viewModals} setViewModals={setViewModals}
                                               loadingOn={loadingOn} loadingOff={loadingOff}
                                               updateErrorsList={updateErrorsList} />
            } catch (error) {
                console.log("error displayConfirmtionaSetupModal", error)
                updateErrorsList(error)
            }
        }
    }

    const displayNotReceivedWardModal = () => {
        if (viewModals.notReceivedModalWard !== null) {
            try {
                return <NotReceivedWardModal requestReplacementWard={requestReplacementWard}
                                             ward={wardDetails.get(viewModals.notReceivedModalWard).ward}
                                             viewModals={viewModals} setViewModals={setViewModals}
                                             loadingOn={loadingOn} loadingOff={loadingOff}
                                             updateErrorsList={updateErrorsList} />
            } catch (error) {
                console.log("error displayNotReceivedWardModal", error)
                updateErrorsList(error)
            }
        }
    }

    const displayRequestReplacementWardModal = () => {
        if (viewModals.requestReplacementModalWard !== null) {
            try {
                return <RequestReplacementWardModal requestReplacementWard={requestReplacementWard}
                                                    ward={wardDetails.get(viewModals.requestReplacementModalWard).ward}
                                                    viewModals={viewModals} setViewModals={setViewModals}
                                                    loadingOn={loadingOn} loadingOff={loadingOff}
                                                    updateErrorsList={updateErrorsList} />
            } catch (error) {
                console.log("error displayRequestReplacementWardModal", error)
                updateErrorsList(error)
            }
        }
    }

    const displayRequestReturnModal = () => {
        if (viewModals.returnRequestedModalWard !== null) {
            try {
                return <RequestReturnModal updateWardStatus={updateWardStatus}
                                           ward={wardDetails.get(viewModals.returnRequestedModalWard).ward}
                                           viewModals={viewModals} setViewModals={setViewModals}
                                           loadingOn={loadingOn} loadingOff={loadingOff}
                                           updateErrorsList={updateErrorsList}
                />
            } catch (error) {
                console.log("error displayRequestReturnModal", error)
                updateErrorsList(error)
            }
        }

    }

    const displayReturnNotReceivedWardModal = () => {
        if (viewModals.returnNotReceivedModalWard !== null) {
            try {
                return <ReturnNotReceivedWardModal returnNotReceived={returnNotReceived}
                                                   ward={wardDetails.get(viewModals.returnNotReceivedModalWard).ward}
                                                   viewModals={viewModals} setViewModals={setViewModals}
                                                   loadingOn={loadingOn} loadingOff={loadingOff}
                                                   updateErrorsList={updateErrorsList} />
            } catch (error) {
                console.log("error displayNotReceivedWardModal", error)
                updateErrorsList(error)
            }
        }
    }

    const displayConfirmationModal = (genericFunction) => {
        try {
            return <GenericConfirmationModal viewModals={viewModals} setViewModals={setViewModals}
                                             loadingOn={loadingOn} loadingOff={loadingOff} />
        } catch (error) {
            console.log("error displayConfirmationModal", error)
        }
    }

    const displayErrorModal = () => {
        try {
            return <ErrorModal errorHandling={errorHandling} setErrorHandling={setErrorHandling} />
        } catch (error) {
            console.log("error displayErrorModal", error)
        }
    }

    const displayModals = () => {
        return <div>
            {displayErrorModal()}
            {displayReauthenticateModal()}
            {displaySignInModal()}
            {displaySignUpModal()}
            {displayDeleteAccountModal()}
            {displayMessageModal()}
            {displayForgotPasswordModal()}
            {displayNewWardModal()}
            {displayReceivedWardModal()}
            {displayConfirmationSetupModal()}
            {displayNotReceivedWardModal()}
            {displayRequestReplacementWardModal()}
            {displayRequestReturnModal()}
            {displayReturnNotReceivedWardModal()}
            {displayConfirmationModal()}
        </div>
    }

    return (displayModals());
}
