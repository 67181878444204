import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";

export default function EnterWardDetails(state) {

    const handleClosePage = () => {
        state.handleModalClose();
    }

    const handleBackPage = () => {
        if (state.prevPage){
            state.handleModalPage(state.prevPage);
        }
    }

    const handleNextPage = () => {
        state.handleModalPage(state.nextPage);
    }

    const handleSetWardValues = (prop) => (event) => {
        try {
            state.setValues({...state.values, [prop]: event.target.value});
        } catch (error) {
            console.log("error handleSetWardValues", error)
            state.updateErrorsList(error)
        }
    };

    const detailsNotEntered = () => {
        return state.values.name === '' || state.values.location === '';
    }

    const enterWardDetails = () => {
        return <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                Create New Ward - Details
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                <FormControl sx={{width: "160px"}} id="ward-name-label" label="ward-name-label" variant="outlined"
                             fullWidth={true}>
                    <InputLabel htmlFor="ward-name-label">Ward Name</InputLabel>
                    <OutlinedInput
                        id="ward-name-input"
                        inputProps={{
                            maxLength: 12,
                            'aria-label': 'ward name input'
                        }}
                        value={state.values.name}
                        onChange={handleSetWardValues('name')}
                        label="Ward Name"
                    />
                </FormControl>
            </Grid>
            <Typography variant="p"  component="p"
                        align={"center"}
                        gutterBottom>
                This is your personal name for this ward and can be changed in the future
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                <FormControl sx={{width: "160px"}} id="ward-location-label" label="ward-location-label"
                             variant="outlined" fullWidth={true}>
                    <InputLabel id="select-location">Location</InputLabel>
                    <Select
                        id="select-location-input"
                        inputProps={{
                            'aria-label': 'ward location select'
                        }}
                        value={state.values.location}
                        onChange={handleSetWardValues('location')}
                        label="location"
                    >
                        <MenuItem value={"Gladius"}>Gladius</MenuItem>
                        <MenuItem value={"Scutum"}>Scutum</MenuItem>
                        <MenuItem value={"Carthago"}>Carthago</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Typography align={"center"}
                        gutterBottom>
                This is the KeyWard storage location we will use to keep the Ward safe
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    <Button variant="contained" id='next-create-ward-page'
                            onClick={handleNextPage} disabled={detailsNotEntered()}>Next</Button>
                    <Button hidden={state.prevPage} variant="contained" id='back-create-ward-page'
                            onClick={handleBackPage}>Back</Button>
                    <Button variant="contained"
                            onClick={handleClosePage}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }

    return enterWardDetails();
}
