import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Checkbox, FormControl, FormControlLabel, FormGroup, Grid} from "@mui/material";

export default function WordSeedDisclaimer(state) {

    const handleConfirmSubmit = () => {
        state.handleSubmit();
    }

    const handleClosePage = () => {
        state.handleModalClose();
    }

    const agreeWardDisclaimer = (prop) => () => {
        try {
            state.setValues({...state.values, [prop]: !state.values.wardDisclaimer});
        } catch (error) {
            console.log("error wardDisclaimer", error)
            state.updateErrorsList(error)
        }
    };

    const note3 = () => {
        return <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                Create New Ward - Word Seed Disclaimer
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography
                        id='disclaimer-note-1'
                        align={"center"}
                        gutterBottom>
                Before requesting a new Ward you must ensure that:
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography
                        id='disclaimer-note-2'
                        align={"center"}
                        gutterBottom>
                - The word seed you intend to submit to KeyWard custody has been generated using the SSS method (Shamir
                Secret Sharing) and is therefore not a Single Backup Key that can be used to access your wallet.
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography
                        id='disclaimer-note-3'
                        align={"center"}
                        gutterBottom>
                - The amount of individual SSS word seeds submitted to KeyWard does not meet or pass your preset
                threshold requirements. In other words the sum of your Wards stored with us cannot be used to access
                your wallet.
            </Typography>
            <FormControl>
                <FormGroup>
                    <FormControlLabel id="disclaimer-checkbox" control={<Checkbox/>}
                                      checked={state.values.wardDisclaimer}
                                      onChange={agreeWardDisclaimer('wardDisclaimer')}
                                      label="By ticking this box I confirm I have read and understood the above"/>
                </FormGroup>
            </FormControl>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    <Button variant="contained"
                            id='submit-create-ward'
                            onClick={handleConfirmSubmit}
                            disabled={!state.values.wardDisclaimer}>Submit</Button>
                    <Button variant="contained"
                            id='cancel-create-ward'
                            onClick={handleClosePage}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }

    return note3();
}
