import {Button, Grid} from "@mui/material";
import * as React from 'react';
import Divider from "@mui/material/Divider";

export default function RequestReplacement(state) {

    const requestReplacementButton = () => {
        if (state.ward.status === "received by you") {
            return <Grid container
                         alignItems="center"
                         direction="column"
                         spacing={2}>
                <Divider orientation="horizontal" flexItem style={{margin: 6}} />
                <Button variant="contained" id='requestReplacement'
                        onClick={e => state.handleDisplayRequestReplacementModal(state.ward.id,true)}>Request Replacement</Button>
            </Grid>
        }
    }

    return (requestReplacementButton());
}
