import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Checkbox, FormControl, FormControlLabel, FormGroup, Grid} from "@mui/material";

export default function NominatedPersonDisclaimer(state) {

    const handleNextPage = () => {
        state.handleModalPage(state.nextPage);
    }

    const handleBackPage = () => {
        state.handleModalPage(state.prevPage);
    }

    const handleClosePage = () => {
        state.handleModalClose();
    }

    const agreeNominatedPersonDisclaimer = (prop) => () => {
        try {
            state.setValues({...state.values, [prop]: !state.values.nominatedPersonDisclaimer});
        } catch (error) {
            console.log("error agreeNominatedPersonDisclaimer", error)
            state.updateErrorsList(error)
        }
    };

    const note2 = () => {
        return <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                Create New Ward - Nominated Persons Disclaimer
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography id='disclaimer-note-1'
                        align={"center"}
                        gutterBottom>
                If you wish to do so, you can enter the Postage Name
                for delivery to a trusted, nominated person other than yourself.
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography id='disclaimer-note-2'
                        align={"center"}
                        gutterBottom>
                Remember if you choose to to use a nominated person, you should ask their permission first.
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Typography id='disclaimer-note-3'
                        align={"center"}
                        gutterBottom>
                Additionally, using a nominated person may affect your ability to retrieve the ward in the future.
                So be sure you fully trust a nominated person before assigning them a Ward.
            </Typography>
            <FormControl>
                <FormGroup>
                    <FormControlLabel id="disclaimer-checkbox" control={<Checkbox/>}
                                      checked={state.values.nominatedPersonDisclaimer}
                                      onChange={agreeNominatedPersonDisclaimer('nominatedPersonDisclaimer')}
                                      label="By ticking this box I confirm I have read and understood the above"/>
                </FormGroup>
            </FormControl>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    <Button  variant="contained"
                             id='next-create-ward-page'
                            onClick={handleNextPage}
                            disabled={!state.values.nominatedPersonDisclaimer}>Next</Button>
                    <Button  variant="contained"
                             id='back-create-ward-page'
                            onClick={handleBackPage}>Back</Button>
                    <Button  variant="contained"
                             id='cancel-create-ward-page'
                            onClick={handleClosePage}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }

    return note2();
}
