import React from 'react';
import {Card, CardContent, Collapse, FormControl, Grid, TextField} from "@mui/material";
import WardCardStatus from "./WardCardStatus";
import WardCardDetails from "./WardCardDetails";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import {createWard, createWardEntity} from "../../factories/WardFactory";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

function WardCard({
                      ward, wardsData, setWardsData, updateWardName,
                      viewModals, setViewModals, index, updateErrorsList
                  }) {

    const updateWard = (k, v) => {
        setWardsData(new Map(wardsData.set(k, v)));
    }

    const handleDisplayDetailsPanel = (id, bool) => {
        const ward = wardsData.get(id).ward;
        updateWard(id, {
            displayDetailsPanel: bool,
            editName: false,
            enteredEditName: '',
            ward: ward
        })
    }

    const handleSetNewName = (id) => (event) => {
        const ward = wardsData.get(id).ward;
        updateWard(id, {
            displayDetailsPanel: false,
            editName: true,
            enteredEditName: event.target.value,
            ward: ward
        })
    }

    const handleDisplaySetNewName = (id, bool) => (event) => {
        const ward = wardsData.get(id).ward;
        updateWard(id, {
            displayDetailsPanel: false,
            editName: bool,
            enteredEditName: '',
            ward: ward
        })
    }

    const handleSubmitNewName = (id) => () => {
        const updatedName = wardsData.get(id).enteredEditName;
        const ward = wardsData.get(id).ward;
        const updatedWard = createWard(id, updatedName,
            ward.location, ward.reference, ward.status, ward.created, ward.lastModified);
        updateWard(id, createWardEntity(id, updatedWard))
        updateWardName(updatedWard);
    }

    const nameEdit = (id) => {
        if (wardsData.get(id).editName) {
            return <Grid container
                         alignItems="center"
                         direction="row"
                         spacing={2}>
                <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                    <CheckCircleOutlineIcon sx={{ml: 4, mb: 2}} id='confirm-name' onClick={handleSubmitNewName(id)}/>
                </Grid>
                <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                    <CancelIcon sx={{ml: 4, mb: 2}} id='cancel-name' onClick={handleDisplaySetNewName(id, false)}/>
                </Grid>
            </Grid>
        }
    }

    const detailsExpand = (ward) => {
        return <Grid container justifyContent="center" direction="column" style={{height: '100%'}}>
            <Collapse in={wardsData.get(ward.id).displayDetailsPanel} timeout={500}>
                <WardCardDetails ward={wardsData.get(ward.id).ward}
                                 viewModals={viewModals}
                                 setViewModals={setViewModals}
                />
            </Collapse>
            <Grid container justifyContent="center" alignItems="flex-end" style={{flexGrow: 1}}>
                {wardsData.get(ward.id).displayDetailsPanel ?
                    <ExpandLessIcon id="expand-less-icon" aria-label={"close details for " +ward.name} onClick={e => handleDisplayDetailsPanel(ward.id, false)}/> :
                    <ExpandCircleDownIcon id="expand-icon" aria-label={"see details for " + ward.name} onClick={e => handleDisplayDetailsPanel(ward.id, true)}/>
                }
            </Grid>
        </Grid>
    }

    return (
            <Card id={ward.reference.replaceAll(' ', '-') + '-card'}>
                <CardContent>
                    <Grid container
                          alignContent={"center"} justifyContent={"center"} alignItems={"stretch"}
                          direction={"row"} spacing={2}>
                        <Grid xs={6} lg={6} md={6} sm={6} xl={6} item>
                            <FormControl fullWidth={true}>
                                <TextField
                                    inputProps={{
                                        maxLength: 12,
                                        'aria-label': "new ward name for " + ward.name
                                    }}
                                    id={'input-new-ward-name-' + ward.reference.replaceAll(' ', '-')} label={ward.name}
                                    variant="outlined"
                                    sx={{width: "80%", ml: 2, mb: 1}}
                                    value={wardsData.get(ward.id).enteredEditName}
                                    onChange={handleSetNewName(ward.id)}
                                    onClick={handleDisplaySetNewName(ward.id, true)}
                                />
                                {nameEdit(ward.id)}
                            </FormControl>
                        </Grid>
                        <WardCardStatus status={ward.status}/>
                        {detailsExpand(ward)}
                    </Grid>
                </CardContent>
            </Card>
    );
}

export default WardCard;
