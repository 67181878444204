import { decryptContent } from "../encryptionService/EncryptionService";
import {createUser} from "../../factories/UserFactory";
import {createPreferences} from "../../factories/PreferencesFactory";
import {createAccount} from "../../factories/AccountFactory";

export async function setEncryptedAccount(functions, doc, setAccountDetails, setPointBalance) {
    const userData = doc.get("user.data");
    if (doc.data() && userData) {
        const decryptedContent = await decryptContent(functions, userData);
        const userJson = JSON.parse(decryptedContent.data.toString());
        const user = createUser(userJson.firstName, userJson.lastName,
            userJson.dob, userJson.leadSource);
        const preferences = createPreferences(doc.get("preferences.menuDrawerAnchor"),
            doc.get("preferences.menuButtonAnchor"));
        setAccountDetails(createAccount(user, preferences));
        setPointBalance(doc.get("points"))
        return true;
    }
    return false;
}
