import * as React from 'react';
import {Grid, Button} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function ConfirmationDisclaimer(state) {

    const handleClosePage = () => {
        state.handleModalClose();
    }

    const handleNextPage = () => {
        state.handleModalPage(state.nextPage)
    }

    const note1 = () => {
        if(state.note1!==null) {
            return <Typography
                        id='disclaimer-note-1'
                        align={"center"}
                        gutterBottom>
                {state.note1}
            </Typography>
        }
    }
    const note2 = () => {
        if(state.note2!==null) {
            return <Typography
                               id='disclaimer-note-2'
                               align={"center"}
                               gutterBottom>
                {state.note2}
            </Typography>
        }
    }
    const note3 = () => {
        if(state.note3!==null) {
            return <Typography
                               id='disclaimer-note-3'
                               align={"center"}
                               gutterBottom>
                {state.note3}
            </Typography>
        }
    }

    const page = () => {
        return <Grid container spacing={2} direction="column" alignItems="center"
              justify="center">
            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                    <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                        {state.title}
                    </Typography>
                    {note1()}
                    {note2()}
                    {note3()}
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                    <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                            <Button variant="contained" id='confirm-button'
                                    onClick={handleNextPage}>Confirm</Button>
                            <Button variant="contained" id='cancel-page'
                                    onClick={handleClosePage}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    }
    return page();
}
