import * as  React from 'react';
import {Container, Grid} from "@mui/material";
import WardCards from "../components/myWardsComponents/WardCards";
import NewWardCard from "../components/myWardsComponents/NewWardCard";

export default function MyWards({
                                    pointBalance,
                                    subscription,
                                    currentUser,
                                    viewModals,
                                    setViewModals,
                                    wardsData,
                                    setWardsData,
                                    updateWardStatus,
                                    updateWardName,
                                    updateErrorsList
                                }) {

    if (!currentUser) {
        return null
    }

    return (
        <Container>
            <Grid container
                  spacing={2}
                  id="my-wards"
                  direction="column" justifyContent={"center"} alignContent={"center"}>
                <Grid item>
                <NewWardCard subscription={subscription} pointBalance={pointBalance}
                             viewModals={viewModals} setViewModals={setViewModals}
                             updateErrorsList={updateErrorsList}/>
                </Grid>
                <WardCards
                    wardsData={wardsData} setWardsData={setWardsData}
                    updateWardName={updateWardName}
                    updateWardStatus={updateWardStatus}
                    viewModals={viewModals} setViewModals={setViewModals}
                    updateErrorsList={updateErrorsList}/>
            </Grid>
        </Container>
    );
}
