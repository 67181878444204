import {Box, Card, CardContent, Collapse, Divider, Grid, Typography} from "@mui/material";
import React, {useMemo, useState} from "react";
import Button from "@mui/material/Button";
import OnetimeTableItems from "../billingComponents/OnetimeTableItems";

const PointsInfo = ({title, children}) => (
    <Box align="center">
        <Typography variant="h6"  align="center" fontWeight="bold" mb={1}>
            {title}
        </Typography>
        <Typography component="span" color="text.primary" fontSize="h5.fontSize">
            {children}
        </Typography>
    </Box>
);

function ExpandMoreIcon() {
    return null;
}

export default function PointBalanceDetails({
                                                payments,
                                                products,
                                                loadingOn,
                                                subscription, wards, pointBalance, updateErrorsList
                                            }) {

    const {annual_bonus, bonus_cap, daily_bonus, secured_bonus_pct} =
    (subscription && subscription.benefits) || {};

    let securedWardsNumber = Array.from(wards.values()).filter(w => w.ward && w.ward.status === 'secured').length;
    let adjustedWardsNumber = bonus_cap && !isNaN(bonus_cap) ? Math.min(securedWardsNumber, bonus_cap) : 0;

    const additionalBonus = useMemo(() => (adjustedWardsNumber && secured_bonus_pct && !isNaN(adjustedWardsNumber) && !isNaN(secured_bonus_pct)) ? adjustedWardsNumber * secured_bonus_pct : 0, [adjustedWardsNumber, secured_bonus_pct]);

    let maxAdditionalBonus = (bonus_cap && secured_bonus_pct && !isNaN(bonus_cap) && !isNaN(secured_bonus_pct)) ? bonus_cap * secured_bonus_pct / 100 : 0;
    let maxDailyBonus = (daily_bonus && maxAdditionalBonus && !isNaN(daily_bonus) && !isNaN(maxAdditionalBonus)) ? daily_bonus * (1 + maxAdditionalBonus) : 0;

    const [isExpanded, setIsExpanded] = useState(false);
    const [isVisible, setVisible] = useState(false);

    let currentBonus = (daily_bonus && additionalBonus && !isNaN(daily_bonus) && !isNaN(additionalBonus)) ? daily_bonus * (1 + additionalBonus / 100) : 0;

    const annualBonusDueOn = useMemo(() => {
        if (subscription) {
            const dueDate = new Date(subscription.created.seconds * 1000);
            dueDate.setFullYear(dueDate.getFullYear() + 1);
            dueDate.setDate(dueDate.getDate() + 1);
            return dueDate.toLocaleDateString();
        }
        return "";
    }, [subscription]);

    return (
        <>
        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" component="div" gutterBottom  align="center" color="primary"
                                        fontWeight="bold">
                                WARD Summary
                            </Typography>
                            <Divider/>
                            <PointsInfo title="Balance">
                                {pointBalance.toLocaleString() || 0}
                            </PointsInfo>
                            <Divider/>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <PointsInfo title="Annual Bonus">
                                        {annual_bonus?.toLocaleString() || 0}
                                    </PointsInfo>
                                </Grid>
                                <Grid item xs={6}>
                                    <PointsInfo title="Due On">
                                        {annualBonusDueOn?.toLocaleString() || "N/A"}
                                    </PointsInfo>
                                </Grid>
                            </Grid>
                            <Divider/>
                            <PointsInfo title="Daily Bonus">
                                {currentBonus?.toLocaleString() || 0}
                            </PointsInfo>
                            <Divider/>
                            <Box display="flex" justifyContent="center">
                                <Button onClick={() => setIsExpanded(!isExpanded)} endIcon={<ExpandMoreIcon/>}>
                                    {isExpanded ? 'Show Less' : 'Show More'}
                                </Button>
                            </Box>
                            <Collapse in={isExpanded}>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <PointsInfo title="Min Daily Bonus">
                                                <Typography component="span" color="text.primary"
                                                            fontSize="h5.fontSize">
                                                    {daily_bonus?.toLocaleString() || 0}
                                                </Typography>
                                            </PointsInfo>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PointsInfo title="Max Daily Bonus">
                                                <Typography component="span" color="text.primary"
                                                            fontSize="h5.fontSize">
                                                    {maxDailyBonus.toLocaleString() || 0}
                                                </Typography>
                                            </PointsInfo>
                                        </Grid>
                                    </Grid>
                                    <Divider/>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <PointsInfo title="Bonus Cap">
                                                <Typography component="span" color="text.primary"
                                                            fontSize="h5.fontSize">
                                                    {bonus_cap?.toLocaleString() || 0}
                                                </Typography>
                                            </PointsInfo>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PointsInfo title="Per Secured %">
                                                <Typography component="span" color="text.primary"
                                                            fontSize="h5.fontSize">
                                                    {secured_bonus_pct ? `${secured_bonus_pct.toFixed(0)}%` : "0%"}
                                                </Typography>
                                            </PointsInfo>
                                        </Grid>
                                    </Grid>
                                    <Divider/>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <PointsInfo title="Wards Secured">
                                                <Typography component="span" color="text.primary"
                                                            fontSize="h5.fontSize">
                                                    {securedWardsNumber.toLocaleString() || 0}
                                                </Typography>
                                            </PointsInfo>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PointsInfo title="Multiplier">
                                                <Typography component="span" color="text.primary"
                                                            fontSize="h5.fontSize">
                                                    {`${additionalBonus.toFixed(0)}%` || "0%"}
                                                </Typography>
                                            </PointsInfo>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                            <Box display="flex" justifyContent="center">
                                {subscription && (subscription.status === "active" || subscription.status === "trialing") &&
                                <Button onClick={() => setVisible(prev => !prev)}>
                                    {isVisible ? 'Hide' : 'Show'} Buy Extra WARD
                                </Button> }
                            </Box>
                        </CardContent>
                    </Card>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                {isVisible && subscription && (subscription.status === "active" || subscription.status === "trialing") &&
                    <OnetimeTableItems products={products} payments={payments}
                                               loadingOn={loadingOn}
                                               updateErrorsList={updateErrorsList}/>}
            </Grid>
    </>
    );
}
