import * as React from 'react';
import {Container, Grid, Popover} from "@mui/material";


import ConfirmationDisclaimer from "../components/disclaimers/ConfirmationDisclaimer";

export default function ReturnNotReceivedWardModal({returnNotReceived,
                                                       ward,
                                                       viewModals, setViewModals,
                                                        loadingOff, loadingOn,
                                                       updateErrorsList,modalSx}) {


    const handleModalClose = () => {
        try {
            setViewModals({
                ...viewModals,
                returnNotReceivedModal: false,
                returnNotReceivedModalWard: null,
            });
            loadingOff();
        } catch (error) {
            console.log("error handleNewWardModalClose", error)
            updateErrorsList(error)
        }
    }

    const handleSubmit = () => {
        loadingOn();
        try {
            returnNotReceived(ward.id, ward.status)
                .then(async (res) => {
                    if (await res.code !== "SUCCESS") {
                        handleModalClose()
                        updateErrorsList(new Error(res.code));
                    } else {
                        handleModalClose();
                    }
                });
        } catch (error) {
            console.log("error handleReturnNotReceivedModal", error)
            updateErrorsList(error)
        }
    }

    return (
            <Popover
                key={0}
                open={viewModals.returnNotReceivedModal}
                anchorEl={document.getElementById('modals')}
                onClose={handleModalClose}
                anchorReference={'anchorPosition'}
                anchorPosition={{
                    left: window.innerWidth / 2,
                    top: window.innerHeight / 2
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                aria-label="not received initial ward pack modal"
            >
                <Container>
                    <Grid container spacing={2} direction="column" textAlign={"center"}
                          sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                    >
                    <ConfirmationDisclaimer
                        title={"Confirm that you have not received the requested Ward: " + ward.reference}
                        note1={"This action will trigger an investigation by our admin team"}
                        nextPage={1}
                        handleModalPage={handleSubmit}
                        handleModalClose={handleModalClose}/>
                </Grid>
                </Container>
        </Popover>
    );
}
