import { doc, collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { setEncryptedAccount } from "./AccountServices";
import { setEncryptedWards } from "./WardServices";

export default function FetchAccountData(auth, db, functions,
                                         setAccountDetails, setWardDetails, setPointBalance) {
    const [currentUser, setCurrentUser] = useState(null);
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
        });
        return () => unsubscribe(); // Cleanup on unmount
    }, [auth]);

    useEffect(() => {
        if (currentUser) {
            const userRef = doc(db, "users", currentUser.uid);
            const wardRef = collection(db, "users", currentUser.uid, "wards");

            const userUnsub = onSnapshot(userRef, (docSnapshot) => {
                    setEncryptedAccount(functions, docSnapshot, setAccountDetails, setPointBalance);
            });

            const wardUnsub = onSnapshot(wardRef, (querySnapshot) => {
                    setEncryptedWards(functions, querySnapshot, setWardDetails);
            });

            // Cleanup on unmount
            return () => {
                userUnsub();
                wardUnsub();
            }
        }
    }, [currentUser, db, functions, setAccountDetails, setPointBalance, setWardDetails]);
};
