import * as  React from 'react';
import {Card, Grid, FormControl, FormLabel} from "@mui/material";
import Button from "@mui/material/Button";

export default function MyAccountDeleteCard({currentUser, viewModals, setViewModals, updateErrorsList}) {


    const handleDeleteAccount = () => {
        try {
            setViewModals({
                ...viewModals,
                deleteAccountModal: true,
            });
        } catch (error) {
            console.log("error handleDeleteAccount", error)
            updateErrorsList(error)
        }

    };

    if (!currentUser) {
        return null;
    }

    return (
            <Card key="anchorSetting">
                <Grid container spacing={2} direction="column"
                      alignItems="center"
                      justifyContent="center">
                    <FormControl>
                        <Grid><br/></Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                            <FormLabel
                                id="delete-account">Delete My Account</FormLabel>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                            <Button variant="contained" id="delete-account-button" onClick={handleDeleteAccount}>Delete</Button>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                    </FormControl>
                </Grid>
            </Card>
    );
}
