import {useState} from 'react';
import {Grid, Typography, FormControlLabel, Switch, Card, CardMedia, Box, Button} from '@mui/material';
import CheckoutSession from "../../services/subscriptionService/CheckoutSession";

const SubscriptionTableItems = ({products, payments, loadingOn, updateErrorsList, productDirection}) => {
    const [isAnnual, setAnnual] = useState(false);

    const toggleChecked = () => {
        setAnnual(!isAnnual);
    };

    return (
        <Grid>
            <Grid container alignItems="center" justifyContent="center">
                <FormControlLabel
                    sx={{mb: 5}}
                    control={<Switch checked={isAnnual} onChange={toggleChecked}/>}
                    label={
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {isAnnual ? 'Annual' : 'Monthly'}
                        </Typography>
                    }
                />
            </Grid>
            <Grid container direction={productDirection} spacing={3} justifyContent="center" alignItems="center">
                {products.subscriptionProducts && products.subscriptionProducts.map((plan, index) => (
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={plan.name}>
                        <SubscriptionTableItem
                            key={plan.name}
                            plan={plan}
                            payments={payments}
                            isAnnual={isAnnual}
                            loadingOn={loadingOn}
                            updateErrorsList={updateErrorsList}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

const SubscriptionTableItem = ({plan, payments, isAnnual, loadingOn, updateErrorsList}) => {
    const monthlyPrice = plan.prices.find(
        (price) => price.recurring.interval === 'month' && price.recurring.interval_count === 1
    );
    const annualPrice = plan.prices.find((price) => price.recurring.interval === 'year');

    const checkout = () => {
        loadingOn();
        CheckoutSession(
            payments,
            isAnnual && annualPrice ? annualPrice.id : monthlyPrice ? monthlyPrice.id : '',
            'subscription_purchase'
        );
    };

    return (
        <Grid container spacing={2} justifyContent="center">
                <Card key={plan.name} sx={{maxWidth: '100vw', mb: 4}}>
                    <Grid container direction="column" justifyContent="space-between" sx={{height: '100%', p: 3}}>
                        {/* Plan Info */}
                        {plan.images[0] && (
                            <Box sx={{width: '100%'}}>
                                <CardMedia
                                    component="img"
                                    sx={{
                                        objectFit: 'contain',
                                        maxHeight: 100,
                                        maxWidth: 100,
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        margin: 'auto'
                                    }}
                                    image={plan.images[0]}
                                    alt={plan.name}
                                />
                            </Box>
                        )}
                        <Box>
                            <Typography variant="h6" align="center">
                                {plan.name}
                            </Typography>
                        </Box>
                        <Box>
                            {plan.description.split(",").map((point, index) => (
                                <Typography key={index} variant="body2" align="center" >
                                    {point.trim()}
                                </Typography>
                            ))}
                        </Box>
                        {/* Pricing and Purchase */}
                        <Box>
                            <Typography variant="h4" align="center">
                                £{(isAnnual && annualPrice ? annualPrice.unit_amount / 100 : monthlyPrice ? monthlyPrice.unit_amount / 100 : 0).toFixed(2)}
                            </Typography>
                            <Typography variant="body1" align="center">
                                {isAnnual && annualPrice ? ' per year' : 'per month'}
                            </Typography>
                            <Button id={`subscribe-${plan.name}`} variant="contained" size="large"
                                    onClick={() => checkout()} fullWidth>
                                Subscribe
                            </Button>
                        </Box>
                    </Grid>
                </Card>
        </Grid>
    );
};

export default SubscriptionTableItems;
