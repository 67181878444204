import * as React from 'react';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select, TextField
} from "@mui/material";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function SignUpModalUserDetails({ values,
                                                   handleSignUpModalChange,
                                                   handleDobChange,
                                                   handleSignUpModalWardPage,
                                                   handleSignUpModalClose}) {

    const detailsNotEntered = () => {
        return values.firstName === '' ||
            values.lastName === '' ||
            values.dob === null;
    }

    const handleNextPage = () => {
        handleSignUpModalWardPage(2)
    }

    const enterDetails = () => {
        return <Grid container spacing={2} direction="column" textAlign={"center"}>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                <Typography id="sign-up" variant="h6" component="h2">
                    Sign up for a new account - enter your details
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign={"center"}>
                <FormControl id="first-name-form" label="FirstName" variant="outlined" fullWidth={true}>
                    <InputLabel htmlFor="first-name">First Name</InputLabel>
                    <OutlinedInput
                        id="first-name-input"
                        inputProps={{
                            'aria-label': 'first name input',
                        }}
                        value={values.firstName}
                        onChange={handleSignUpModalChange('firstName')}
                        label="First Name"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign={"center"}>
                <FormControl id="last-name-form" label="LastName" variant="outlined" fullWidth={true}>
                    <InputLabel htmlFor="last-name">Last Name</InputLabel>
                    <OutlinedInput
                        id="last-name-input"
                        inputProps={{
                            'aria-label': 'last name input',
                        }}
                        value={values.lastName}
                        onChange={handleSignUpModalChange('lastName')}
                        label="Last Name"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign={"center"}>
                <FormControl id="customer-dob" label="Date of Birth" variant="outlined" fullWidth={true}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            label="Date of Birth"
                            inputFormat="dd/MM/yyyy"
                            inputProps={{
                                'aria-label': 'dob input',
                            }}
                            value={values.dob}
                            openTo="year"
                            maxDate={new Date().setFullYear(new Date().getFullYear() - 16)}
                            disableFuture={true}
                            onChange={handleDobChange}
                            showToolbar={false}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid><br/></Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign={"center"}>
                    <FormControl id="customer-referral" variant="outlined" fullWidth={true}>
                        <InputLabel id="customer-referral">Where did you hear about us?</InputLabel>
                        <Select
                            id="customer-referral-input"
                            value={values.leadSource}
                            label="Where did you hear about us?"
                            onChange={handleSignUpModalChange('leadSource')}
                        >
                            <MenuItem value="-None-">
                                <em>-None-</em>
                            </MenuItem>
                            <MenuItem value={'Google'}>Google</MenuItem>
                            <MenuItem value={'Reddit'}>Reddit</MenuItem>
                            <MenuItem value={'Facebook'}>Facebook</MenuItem>
                            <MenuItem value={'Twitter'}>Twitter</MenuItem>
                            <MenuItem value={'Direct'}>Direct</MenuItem>
                            <MenuItem value={'Trade Show'}>Trade Show</MenuItem>
                            <MenuItem value={'Other'}>Other</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                    <Button variant="contained" onClick={handleNextPage} id="next-page-button"
                            aria-label="next page button" disabled={detailsNotEntered()}>Next</Button>
                </Grid>
                <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                    <Button variant="contained" onClick={handleSignUpModalClose} label="cancel-button"
                            aria-label="cancel button">Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }

    return (enterDetails());
}
