import * as React from 'react';
import {
    FormControl,
    Grid, InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Typography from "@mui/material/Typography";

export default function SignUpModalEmailDetails({values,
                                                    handleSignUpModalChange,
                                                    handleClickShowPassword,
                                                    handleMouseDownPassword,
                                                    handleClickShowConfirmPassword,
                                                    handleSignUpModalWardPage,
                                                    signUp,
                                                    handleSignUpModalClose}) {

    const detailsNotEntered = () => {
        return values.email === '' ||
            values.confirmEmail === '' ||
            values.password === '' ||
            values.confirmPassword === '';
    }

    const handleBackPage = () => {
        handleSignUpModalWardPage(1)
    }

    const enterDetails = () => {
        return <Grid container spacing={2} direction="column" alignItems="center" justify="center" textAlign="center">
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                <Typography id="sign-up" variant="h6" component="h2" textAlign="center">
                    Sign up for a new account - enter your email and password
                </Typography>
            </Grid>

            <Grid container spacing={2} direction="column" textAlign={"center"}>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <FormControl id="email-address-form" label="Email Address" variant="outlined" fullWidth={true}>
                        <InputLabel htmlFor="email-address">Email Address</InputLabel>
                        <OutlinedInput
                            id="email-address-input"
                            inputProps={{
                                'aria-label': 'email input',
                            }}
                            value={values.email}
                            onChange={handleSignUpModalChange('email')}
                            label="Email Address"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <FormControl id="confirm-email-address-form" label="Confirm Email Address" variant="outlined" fullWidth={true}>
                        <InputLabel htmlFor="confirm-email-address">Confirm Email Address</InputLabel>
                        <OutlinedInput
                            id="confirm-email-address"
                            inputProps={{
                                'aria-label': 'confirm email input',
                            }}
                            value={values.confirmEmail}
                            onChange={handleSignUpModalChange('confirmEmail')}
                            label="Confirm Email Address"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <FormControl id="password" label="Password" variant="outlined" fullWidth={true}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password-input"
                            inputProps={{
                                'aria-label': 'password input',
                            }}
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleSignUpModalChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <FormControl id="confirm-password" label="Confirm Password" variant="outlined" fullWidth={true}>
                        <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="confirm-password"
                            inputProps={{
                                'aria-label': 'confirm password input',
                            }}
                            type={values.showConfirmPassword ? 'text' : 'password'}
                            value={values.confirmPassword}
                            onChange={handleSignUpModalChange('confirmPassword')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm Password"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <Button variant="contained" onClick={signUp} id="signup-button" disabled={detailsNotEntered()}>Sign Up</Button>
                    <Button variant="contained" id="back-button" onClick={handleBackPage}>Back</Button>
                    <Button variant="contained" id="cancel-button" onClick={handleSignUpModalClose}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }

    return (enterDetails());
}
