import React from 'react';
import {Grid, Card, CardContent, Button, useTheme, useMediaQuery, CardMedia} from "@mui/material";
import ReactMarkdown from 'react-markdown';

export const ContentCardL = ({ title, description, expandedDescription, buttonData, image, isExpanded, onExpandClick }) => {
    const theme = useTheme();
    const isXLScreen = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" style={{
            width: isXLScreen ? '70%' : '100%', // 70% width on XL screens, full width otherwise
            marginLeft: isXLScreen ? 'auto' : '0', // Auto margin on the left on XL screens for centering
            marginRight: isXLScreen ? '5%' : 'auto', // Small margin on the right on XL screens for spacing
        }}>
            <Card>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={image ? 8 : 12}
                        md={image ? 8 : 12}
                        lg={image ? 8 : 12}
                        xl={image ? 8 : 12}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CardContent style={{
                            width: '100%',
                            textAlign: 'center',
                        }}>
                            <ReactMarkdown>{title}</ReactMarkdown>
                            <ReactMarkdown>{description}</ReactMarkdown>
                            {buttonData && (
                                buttonData.url === '#' ? (
                                    <Button variant="contained" onClick={onExpandClick}>
                                        {isExpanded ? 'Show Less' : buttonData.text}
                                    </Button>
                                ) : (
                                    <Button variant="contained" href={buttonData.url} target="_blank" rel="noopener noreferrer">
                                        {buttonData.text}
                                    </Button>
                                )
                            )}
                        </CardContent>
                    </Grid>
                    {image && (
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CardMedia
                                component="img"
                                image={image}
                                style={{
                                    maxHeight: '80%',
                                    maxWidth: '80%',
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Card>
        </Grid>
    );
};
