import {httpsCallable} from "firebase/functions";
import {contentToJSON} from "../../factories/ContentFactory";

export async function encryptContent(functions, content) {
    try {
        const encryptContentCallable = httpsCallable(functions, 'encryptContent');
        return await encryptContentCallable(contentToJSON(content));
    } catch (error) {
        console.error(`Error in encryptContent function: ${error}`);
        throw error;
    }
}

export async function decryptContent(functions, content) {
    try {
        const decryptContentCallable = httpsCallable(functions, 'decryptContent');
        return await decryptContentCallable(contentToJSON(content));
    } catch (error) {
        console.error(`Error in decryptContent function: ${error}`);
        throw error;
    }
}

export async function decryptMap(functions, content) {
    try {
        const decryptMapCallable = httpsCallable(functions, 'decryptMap');
        return await decryptMapCallable(content);
    } catch (error) {
        console.error(`Error in decryptContentMap function: ${error}`);
        throw error;
    }
}
