import {httpsCallable} from "firebase/functions";
import CreateNewWard from "./CreateNewWard";

export default async function RequestReplacementWard(auth, functions, ward, values, action) {
    const statusUpdate = httpsCallable(functions, 'updateWardStatus');
    const wardData = {
        id: ward.id,
        status: ward.status,
        action: action
    };

    try {
        const res = await statusUpdate(wardData);

        if (res.data.response === "SUCCESS") {
            const newWardValues = {
                name: values.name,
                location: values.location,
                postageName: values.postageName,
                line1: values.line1,
                line2: values.line2,
                town: values.town,
                postcode: values.postcode,
                postageNameConf: values.postageNameConf,
                line1Conf: values.line1Conf,
                line2Conf: values.line2Conf,
                townConf: values.townConf,
                postcodeConf: values.postcodeConf,
            };

            return await CreateNewWard(auth, functions, newWardValues);
        } else {
            return res.data.response
        }
    } catch(error) {
        console.error(`Error in RequestReplacementWard function: ${error}`);
        return { success: false, error: error.message };
    }
}
