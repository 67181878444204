
// Third-party packages
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useState, useMemo} from "react";
import {EmailAuthProvider, reauthenticateWithCredential, sendPasswordResetEmail} from "firebase/auth";
import FirebaseStart from '../FirebaseStart';

// Pages components
import MenuBar from './components/menuComponents/MenuBar';
import MyWards from './pages/MyWards';
import MyAccount from './pages/MyAccount';
import Learn from './pages/Learn';
import Home from './pages/Home';

// Factories
import {initAccount} from './factories/InitAccountFactory';
import {initViewModals} from './factories/InitViewModalsFactory';

// Modals
import DisplayModals from './modals/DisplayModals';

// services auth
import SignInAuthAccount from './services/authService/SignInAuthAccount';
import SignOutOfAuthAccount from './services/authService/SignOutOfAuthAccount';
import CreateNewWard from './services/wardService/CreateNewWard';
import CreateNewAuthAccount from './services/authService/CreateNewAuthAccount';
import DeleteAccount from './services/authService/DeleteAccount';
import FetchAccountData from './services/accountService/FetchAccountData';
import UpdateAccountPreferences from './services/accountService/UpdateAccountPreferences';
import UpdateWardName from './services/wardService/UpdateWardName';
import UpdateWardStatus from './services/wardService/UpdateWardStatus';
import RequestReplacementWard from './services/wardService/RequestReplacementWard';
import VerifyWardReceived from './services/wardService/VerifyWardReceived';
import ReturnNotReceived from "./services/wardService/ReturnNotReceived";
import FetchSubscription from "./services/subscriptionService/FetchSubscription";

// Utilities
import UpdateErrorsList from './functions/UpdateErrorsList';
import RedactEmail from './functions/RedactEmail';
import MyPoints from "./pages/MyPoints";
import Loading from "./functions/Loading";
import ExtendSubscription from "./services/subscriptionService/ExtendSubscription";
import TheStory from "./pages/TheStory";
import FooterBar from "./components/menuComponents/FooterBar";
import FetchProducts from "./services/subscriptionService/FetchProducts";
import './App.css';
import {ArticleContentViewer} from "./services/contentService/ContentServices";
import NotFound from "./pages/NotFound";
import Legal from "./pages/Legal";

const initialAccountDetails = initAccount();
const initialWardDetails = new Map([]);
const initialViewModals = initViewModals();

export default function App() {

    const [subscription, setSubscription] = useState([])
    const [products, setProducts] = useState([]);
    const [accountData, setAccountData] = useState(initialAccountDetails);
    const [wardsData, setWardsData] = useState(initialWardDetails);
    const [pointBalance, setPointBalance] = useState(0)
    const [pointsTransactions, setPointsTransactions] = useState([]);
    const [viewModals, setViewModals] = useState(initialViewModals);

    const [load, setLoad] = useState(false);

    const loadingOn = () => {
        setLoad(true);
    };
    const loadingOff = () => {
        setLoad(false);
    };

    const isLoading = () => {
        return load;
    };

    const [errorHandling, setErrorHandling] = useState({
        errorModal: false,
        errorsList: [],
    });

    const {auth, functions, db, payments} = FirebaseStart();

    const currentUser = useMemo(() => auth.currentUser, [auth.currentUser]);
    const redactedEmail = useMemo(() => {
        if (currentUser) {
            return RedactEmail(auth.currentUser.email, currentUser);
        }
    }, [auth.currentUser, currentUser]);

    FetchAccountData(auth, db, functions, setAccountData, setWardsData, setPointBalance);
    FetchSubscription(auth, db, setSubscription);
    FetchProducts(payments, setProducts);

    const updateErrorsList = (error) => {
        UpdateErrorsList(error, errorHandling, setErrorHandling);
    };

    const displayMenuModals = () => {
        try {
            return DisplayModals(
                createAccount,
                deleteAccount,
                signInToAccount,
                signOutOfAccount,
                reauthenticateAccount,
                forgotPassword,
                wardsData,
                createNewWard,
                requestReplacementWard,
                updateWardStatus,
                verifyWardReceived,
                returnNotReceived,
                viewModals,
                setViewModals,
                errorHandling,
                setErrorHandling,
                isLoading,
                loadingOn,
                loadingOff,
                updateErrorsList
            );
        } catch (error) {
            console.log('error displayModals', error);
            updateErrorsList(error);
        }
    };

    const createAccount = (values, setValues) => {
        return CreateNewAuthAccount(
            auth,
            functions,
            accountData,
            setAccountData,
            viewModals,
            setViewModals,
            values,
            setValues,
            loadingOff,
            updateErrorsList
        );
    };

    const signInToAccount = (values, setValues) => {
        SignInAuthAccount(
            auth,
            db,
            functions,
            values,
            setValues,
            viewModals,
            setViewModals,
            setAccountData,
            loadingOff,
            updateErrorsList
        );
    };

    const reauthenticateAccount = (password) => {
        const credential = EmailAuthProvider.credential(auth.currentUser.email, password);
        return reauthenticateWithCredential(auth.currentUser, credential);
    };

    const forgotPassword = (email) => {
        return sendPasswordResetEmail(auth, email);
    };

    const deleteAccount = () => {
        return DeleteAccount(auth, functions);
    };

    const updateAccountPreferences = (account) => {
        return UpdateAccountPreferences(auth, db, functions, account);
    };

    const createNewWard = (values) => {
        return CreateNewWard(auth, functions, values);
    };

    const requestReplacementWard = (ward, values, action) => {
        return RequestReplacementWard(auth, functions, ward, values, action);
    };

    const updateWardName = (ward) => {
        return UpdateWardName(auth, db, functions, ward);
    };

    const updateWardStatus = (id, status) => {
        return UpdateWardStatus(functions, id, status);
    };

    const returnNotReceived = (id, status) => {
        return ReturnNotReceived(functions, id, status);
    };

    const verifyWardReceived = (ward, values) => {
        return VerifyWardReceived(functions, ward, values);
    };

    const signOutOfAccount = () => {
        try {
            return SignOutOfAuthAccount(auth, setAccountData, setWardsData, viewModals, setViewModals);
        } catch (error) {
            console.log('error handleSignOutOfAccount', error);
            updateErrorsList(error);
        }
    };

    const extendSubscription = (quantity) => {
        try {
            return ExtendSubscription(functions, quantity);
        } catch (error) {
            console.log('error extendSubscription', error);
            updateErrorsList(error);
        }
    }

    return (
        <div id="App" >
            <BrowserRouter id="browser-router">
                <header aria-label="App-header" id="App-header">
                    <MenuBar
                        currentUser={currentUser}
                        pointBalance={pointBalance}
                        signOutOfAccount={signOutOfAccount}
                        menuButtonAnchor={accountData.preferences.menuButtonAnchor}
                        menuDrawerAnchor={accountData.preferences.menuDrawerAnchor}
                        viewModals={viewModals}
                        setViewModals={setViewModals}
                        updateErrorsList={updateErrorsList}
                    />
                </header>
                <main aria-label="App-main" id="App-main" >
                    <Loading load={load}/>
                    {displayMenuModals()}
                    <div id={"modals"}/>
                    <Routes id="route-all">
                        <Route id="route-home" exact path="/" element={<Home currentUser={currentUser}
                                                                             payments={payments}
                                                                             products={products}
                                                                             subscription={subscription}
                                                                             loadingOn={loadingOn}
                                                                             viewModals={viewModals}
                                                                             setViewModals={setViewModals}
                                                                             updateErrorsList={updateErrorsList}/>}/>
                        <Route id="route-story" exact path="/the-crypto-story" element={<TheStory/>}/>
                        <Route
                            id="route-points"
                            exact
                            path="/points"
                            element={
                                <MyPoints
                                    wards={wardsData}
                                    auth={auth}
                                    db={db}
                                    products={products}
                                    payments={payments}
                                    pointBalance={pointBalance}
                                    pointsTransactions={pointsTransactions}
                                    setPointsTransactions={setPointsTransactions}
                                    subscription={subscription}
                                    currentUser={currentUser}
                                    loadingOn={loadingOn}
                                    updateErrorsList={updateErrorsList}
                                />
                            }
                        />
                        <Route
                            id="route-wards"
                            exact
                            path="/wards"
                            element={
                                <MyWards
                                    pointBalance={pointBalance}
                                    subscription={subscription}
                                    currentUser={currentUser}
                                    payments={payments}
                                    viewModals={viewModals}
                                    setViewModals={setViewModals}
                                    wardsData={wardsData}
                                    setWardsData={setWardsData}
                                    updateWardStatus={updateWardStatus}
                                    updateWardName={updateWardName}
                                    updateErrorsList={updateErrorsList}
                                />
                            }
                        />
                        <Route
                            id="route-account"
                            path="/account"
                            element={
                                <MyAccount
                                    auth={auth}
                                    products={products}
                                    payments={payments}
                                    currentUser={currentUser}
                                    redactedEmail={redactedEmail}
                                    pointBalance={pointBalance}
                                    extendSubscription={extendSubscription}
                                    accountData={accountData}
                                    setAccountData={setAccountData}
                                    subscription={subscription}
                                    viewModals={viewModals}
                                    setViewModals={setViewModals}
                                    functions={functions}
                                    updateAccountPreferences={updateAccountPreferences}
                                    loadingOn={loadingOn} loadingOff={loadingOff}
                                    updateErrorsList={updateErrorsList}
                                />
                            }
                        />
                        <Route id="route-learn" path="/learn" element={<Learn/>}/>
                        <Route id="route-article" path="/learn/:path" element={<ArticleContentViewer/>} />
                        <Route id="route-404" path="*" element={<NotFound />} />
                        <Route id="route-legal" path="/:path" element={<Legal />} />
                    </Routes>
                </main>
                <footer>
                    <FooterBar/>
                </footer>
            </BrowserRouter>
        </div>
    );
}

