import * as React from 'react';
import {Container, Grid, Link, Popover} from "@mui/material";


import DoesStringNotEqual from "../../functions/DoesStringNotEqual";

import VerifyShippingAddress from "../components/verify/VerifyShippingAddress";
import VerifyShippingReference from "../components/verify/VerifyShippingReference";
import ConfirmActionStep from "../components/ConfirmationActionStep";
import Typography from "@mui/material/Typography";
import {initShippingValuesFactory} from "../../factories/InitShippingValuesFactory";

const style = {
    typography: {
        typographyValue: {
            fontSize: 14,
        }
    }
};

export default function VerifyWardModal({
                                            verifyWardReceived,
                                            ward,
                                            viewModals, setViewModals,
                                            loadingOff, loadingOn,
                                            updateErrorsList, modalSx
                                        }) {

    const [values, setValues] = React.useState(initShippingValuesFactory());

    const firstWord = ward.reference.split(" ").slice(0, 1).join("");

    const handleSubmit = () => {
        try {
            if (DoesStringNotEqual(values.shippingRef, '',
                "You must enter the Shipping Reference")) {
                if (DoesStringNotEqual(values.shippingBusinessName, '',
                    "You must enter the Shipping Name")) {
                    if (DoesStringNotEqual(values.shippingLine1, '',
                        "You must enter the Shipping Line 1")) {
                        if (DoesStringNotEqual(values.shippingLine2, '',
                            "You must enter the Shipping Line 2")) {
                            if (DoesStringNotEqual(values.shippingTown, '',
                                "You must enter the Shipping Town")) {
                                if (DoesStringNotEqual(values.shippingPostcode, '',
                                    "You must enter the Shipping Postcode")) {
                                    verifyWard().then((response) => {
                                        loadingOff();
                                        if (response.response !== 'SUCCESS') {
                                            updateErrorsList(new Error(response.response));
                                        } else {
                                            handleModalClose();
                                        }
                                    });
                                }
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.log("error handleSubmit", error)
            updateErrorsList(error)
        }
    }

    const verifyWard = () => {
        loadingOn();
        return verifyWardReceived(ward, values);
    };

    const handleModalClose = () => {
        try {
            setViewModals({
                ...viewModals,
                verifyReceivedModal: false,
                verifyReceivedModalWard: null
            })
            setValues({
                ...values,
                shippingRef: '',
                shippingBusinessName: '',
                shippingLine1: '',
                shippingLine2: '',
                shippingTown: '',
                shippingPostcode: ''
            });
        } catch (error) {
            console.log("error handleModalClose", error)
            updateErrorsList(error)
        }
    }

    const [verifyWardPage, setVerifyWardPage] = React.useState(1);
    // 1 VerifyShippingReference
    // 2 VerifyShippingAddress
    const handleModalPage = (n) => {
        if (n < 4 && n > 0) {
            setVerifyWardPage(n);
        }
    }

    const wardModalPage = () => {
        switch (verifyWardPage) {
            case 1:
                return (<ConfirmActionStep
                    handleModalPage={handleModalPage}
                    stepTitle="Confirm the contents of the Ward Pack"
                    stepInfo="Open the package and ensure the Ward Pack contains the following items:"
                    stepImage={process.env.PUBLIC_URL + '/KeywardWardPackAllItems202306.svg'}
                    stepIssue={
                        <Typography variant="body2" component="div">
                            If any items are missing, please alert our customer services team as soon as possible
                            via <Link href="mailto:customerservice@keyward.com">customerservice@keyward.com</Link>
                        </Typography>
                    }
                    onConfirm={2}
                    handleModalClose={handleModalClose}
                />)
            case 2:
                return (<VerifyShippingReference
                    style={style}
                    values={values}
                    setValues={setValues}
                    firstWord={firstWord}
                    updateErrorsList={updateErrorsList}
                    nextPage={3}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>);
            case 3:
                return (<VerifyShippingAddress
                    style={style}
                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={2}
                    handleModalPage={handleModalPage}
                    handleSubmit={handleSubmit}
                    handleModalClose={handleModalClose}/>);
            default:
                return (<VerifyShippingReference
                    style={style}
                    values={values}
                    setValue={setValues}
                    firstWord={firstWord}
                    updateErrorsList={updateErrorsList}
                    nextPage={2}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>);
        }
    }

    return (
        <Popover
            key={0}
            open={viewModals.verifyReceivedModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="verify ward modal"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    {wardModalPage()}
                </Grid>
            </Container>
        </Popover>
    );
}
