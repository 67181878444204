export function createPreferences(menuDrawerAnchor, menuButtonAnchor) {
    return {
            menuDrawerAnchor: menuDrawerAnchor,
            menuButtonAnchor: menuButtonAnchor,
    };
}

export function updatePreferences(menuDrawerAnchor, menuButtonAnchor) {
    return {
        preferences: {
            menuDrawerAnchor: menuDrawerAnchor,
            menuButtonAnchor: menuButtonAnchor,
        }
    };
}

