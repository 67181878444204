import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {FormControl, Grid, InputLabel, OutlinedInput} from "@mui/material";

export default function EnterAddressConfirmation(state) {

    const handleNextPage = () => {
        state.handleModalPage(state.nextPage);
    }

    const handleBackPage = () => {
        state.handleModalPage(state.prevPage);
    }

    const handleClosePage = () => {
        state.handleModalClose();
    }

    const handleSetWardValues = (prop) => (event) => {
        try {
            state.setValues({...state.values, [prop]: event.target.value});
        } catch (error) {
            console.log("error handleSetWardValues", error)
            state.updateErrorsList(error)
        }
    };

    const detailsNotEntered = () => {
        return state.values.postageNameConf === '' || state.values.line1Conf === ''
            || state.values.townConf === '' || state.values.postcodeConf === '';
    }

    const enterAddressConfirmation = () => {
        return <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                Create New Ward - Address Confirmation
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                <FormControl id="ward-postage-name-label-conf" label="ward-postage-name-label-conf" variant="outlined"
                             fullWidth={true}>
                    <InputLabel htmlFor="ward-postage-name-label-conf">Postage Name</InputLabel>
                    <OutlinedInput
                        id="ward-postage-name-input-conf"
                        inputProps={{
                            maxLength: 64,
                            'aria-label': 'postage name confirmation'
                        }}
                        value={state.values.postageNameConf}
                        onChange={handleSetWardValues('postageNameConf')}
                        label="Postage Name"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                <FormControl id="ward-line1-label-conf" label="ward-line1-label-conf" variant="outlined"
                             fullWidth={true}>
                    <InputLabel htmlFor="ward-line1-label-conf">Address Line 1</InputLabel>
                    <OutlinedInput
                        id="ward-line1-input-conf"
                        inputProps={{
                            maxLength: 64,
                            'aria-label': 'address line 1 confirmation'
                        }}
                        value={state.values.line1Conf}
                        onChange={handleSetWardValues('line1Conf')}
                        label="Address Line 1"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                <FormControl id="ward-line2-label-conf" label="ward-line2-label-conf" variant="outlined"
                             fullWidth={true}>
                    <InputLabel htmlFor="ward-line2-label-conf">Address Line 2</InputLabel>
                    <OutlinedInput
                        id="ward-line2-input-conf"
                        inputProps={{
                            maxLength: 64,
                            'aria-label': 'address line 2 confirmation'
                        }}
                        value={state.values.line2Conf}
                        onChange={handleSetWardValues('line2Conf')}
                        label="Address Line 2"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                <FormControl id="ward-town-label-conf" label="ward-town-label-conf" variant="outlined" fullWidth={true}>
                    <InputLabel htmlFor="ward-town-label-conf">Town</InputLabel>
                    <OutlinedInput
                        id="ward-town-input-conf"
                        inputProps={{
                            maxLength: 64,
                            'aria-label': 'town confirmation'
                        }}
                        value={state.values.townConf}
                        onChange={handleSetWardValues('townConf')}
                        label="Town"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                <FormControl id="ward-postcode-label-conf" label="ward-postcode-label-conf" variant="outlined"
                             fullWidth={true}>
                    <InputLabel htmlFor="ward-name-label-conf">Post Code</InputLabel>
                    <OutlinedInput
                        id="ward-postcode-input-conf"
                        inputProps={{
                            maxLength: 7,
                            'aria-label': 'post code confirmation'
                        }}
                        value={state.values.postcodeConf}
                        onChange={handleSetWardValues('postcodeConf')}
                        label="Post Code"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    <Button variant="contained"
                            id='next-create-ward-page'
                            onClick={handleNextPage} disabled={detailsNotEntered()}>Next</Button>
                    <Button variant="contained"
                            id='back-create-ward-page'
                            onClick={handleBackPage}>Back</Button>
                    <Button variant="contained"
                            id='cancel-create-ward-page'
                            onClick={handleClosePage}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }

    return enterAddressConfirmation();
}
