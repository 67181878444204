import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Grid } from '@mui/material';
import MenuButtonAnchorCard from "./MenuButtonAnchorCard";
import MenuDrawerAnchorCard from "./MenuDrawerAnchorCard";

export default function AccessibilityOptionsGroup(props) {
    const { updateErrorsList } = props;
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h4" align="center" fontWeight="bold" color="primary">
                                Accessibility Options
                            </Typography>
                            <Grid container justifyContent="center" marginTop={2}>
                                <Button onClick={() => setIsExpanded(!isExpanded)}>
                                    {isExpanded ? "Hide Options" : "Show Options"}
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
            </Grid>
            {isExpanded && (
                <>
                    <Grid item>
                        <MenuButtonAnchorCard  {...props} />
                    </Grid>
                    <Grid item>
                        <MenuDrawerAnchorCard  {...props} />
                    </Grid>
                </>
            )}
        </Grid>
    );
}
