import * as  React from 'react';

import {Card, CardContent, Grid} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";

export default function NewWardCard({
                                        subscription,
                                        pointBalance,
                                        viewModals, setViewModals,
                                        updateErrorsList
                                    }) {

    if (!subscription || (subscription && (subscription.status !== "active" && subscription.status !== "trialing"))) {
        return null;
    }

    const handleNewWardModalOpen = () => {
        try {
            if (pointBalance >= subscription.benefits.ward_spend) {
                setViewModals({
                    ...viewModals,
                    newWardModal: true
                });
            } else {
                const error = new Error(`At least ${subscription.benefits.ward_spend.toLocaleString()} WARD are required to secure a new Ward.`);
                console.log(error.message);
                updateErrorsList(error);
            }
        } catch (error) {
            console.log("error handleNewWardModal", error)
            updateErrorsList(error)
        }
    }

    try {
        return (
                <Card onClick={handleNewWardModalOpen}>
                    <CardContent>
                        <Grid container spacing={2} direction="column"
                              alignItems="center"
                              justifyContent="center">
                            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                                <Button id="create-new-ward-button" variant="contained" endIcon={<AddIcon/>}>
                                    Create a New Ward
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
        );
    } catch (error) {
        console.log("error keyLabel", error)
        updateErrorsList(error)
    }
}
