import Button from "@mui/material/Button";
import RedirectToBillingPortal from "../../services/subscriptionService/RedirectToBillingPortal";

export default function RedirectToBillingPortalButton({loadingOn, functions}) {
    return (
        <Button
            onClick={() => RedirectToBillingPortal(loadingOn, functions)}
            id="manage-subscriptions"
            variant="contained"
        >
            Manage Subscription
        </Button>
    );
}
