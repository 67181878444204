import {Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import * as React from 'react';
import CompleteSetup from "./buttons/CompleteSetup";
import RequestReplacement from "./buttons/RequestReplacement";
import VerifyReceived from "./buttons/VerifyReceived";
import NotReceived from "./buttons/NotReceived";
import RequestReturn from "./buttons/RequestReturn";
import ReturnNotReceived from "./buttons/ReturnNotReceived";
import Box from "@mui/material/Box";

export default function WardCardDetails({ward, viewModals, setViewModals}) {

    const handleDisplayReturnRequestedModal = (id, bool) => {
        setViewModals({
            ...viewModals,
            returnRequestedModalWard: id,
            returnRequestedModal: bool
        })
    }

    const handleDisplayNotReceivedModal = (id, bool) => {
        setViewModals({
            ...viewModals,
            notReceivedModalWard: id,
            notReceivedModal: bool
        })
    }

    const handleDisplayRequestReplacementModal = (id, bool) => {
        setViewModals({
            ...viewModals,
            requestReplacementModalWard: id,
            requestReplacementModal: bool
        })
    }

    const handleDisplayReturnNotReceivedModal = (id, bool) => {
        setViewModals({
            ...viewModals,
            returnNotReceivedModalWard: id,
            returnNotReceivedModal: bool
        })
    }

    const handleDisplayVerifyReceivedModal = (id, bool) => {
        setViewModals({
            ...viewModals,
            verifyReceivedModalWard: id,
            verifyReceivedModal: bool
        })
    }

    const handleDisplayConfirmationSetupModal = (id, bool) => {
        setViewModals({
            ...viewModals,
            confirmationSetupModalWard: id,
            confirmationSetupModal: bool,
        })
    }

    const displayButtons = () => {
        return <Stack spacing={2}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{mt: 4, mb: 4}}>
            <CompleteSetup ward={ward}
                           handleDisplayConfirmationSetupModal={handleDisplayConfirmationSetupModal}/>

            <VerifyReceived ward={ward}
                            handleDisplayVerifyReceivedModal={handleDisplayVerifyReceivedModal}/>

            <RequestReturn ward={ward}
                           handleDisplayReturnRequestedModal={handleDisplayReturnRequestedModal}/>


            <NotReceived ward={ward}
                         handleDisplayNotReceivedModal={handleDisplayNotReceivedModal}/>

            <RequestReplacement ward={ward}
                                handleDisplayRequestReplacementModal={handleDisplayRequestReplacementModal}/>

            <ReturnNotReceived ward={ward}
                               handleDisplayReturnNotReceivedModal={handleDisplayReturnNotReceivedModal}/>
        </Stack>
    }

    return (
        <Box sx={{width: '100%'}}>
            <Divider/>
            <Grid container alignItems="center" direction="row" spacing={2}>
                <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                    <Typography
                        id="location-key"
                        color="text.primary"
                        align={"center"}
                        gutterBottom>
                        Location:
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                    <Typography
                        id="location-value"

                        align={"center"}
                        gutterBottom>
                        {ward.location}
                    </Typography>
                </Grid>
            </Grid>
            <Divider/>
            <Grid container alignItems="center" direction="row" spacing={2}>
                <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                    <Typography
                        id="reference-key"
                        color="text.primary"
                        align={"center"}
                        gutterBottom>
                        Reference:
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                    <Typography
                        id="reference-value"

                        align={"center"}
                        gutterBottom>
                        {ward.reference}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}
                  alignItems="center"
                  justifyContent="center">
                {displayButtons()}
            </Grid>
        </Box>
    )
}
