import {Button, Grid} from "@mui/material";
import * as React from 'react';
import Divider from "@mui/material/Divider";

export default function NotReceived(state) {

    const inTransitForLessThanTenDays = (ward) => {
        let diff = ward.lastModified - Date.now();
        let diffDays = (diff / (1000 * 3600 * 24))*-1;
        return diffDays < 10;
    }

    const notReceivedButton = () => {
        if (state.ward.status === "in transit to you") {
            return <Grid container
                         alignItems="center"
                         direction="column"
                         spacing={2}>
                <Divider orientation="horizontal" flexItem style={{margin: 6}}/>
                <Button disabled={inTransitForLessThanTenDays(state.ward)} variant="contained" id='notReceived'
                        onClick={e => state.handleDisplayNotReceivedModal(state.ward.id,true)}>Not Received</Button>
            </Grid>
        }
    }

    return (notReceivedButton());
}
