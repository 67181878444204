import * as  React from 'react';
import {Card, Grid, FormControl, FormLabel} from "@mui/material";
import Button from "@mui/material/Button";
import {sendPasswordResetEmail} from "firebase/auth";

import {useState} from "react";

export default function MyPasswordResetCard({auth, currentUser, viewModals, setViewModals, loadingOn, loadingOff, updateErrorsList}) {

    const getEmail = () => {
        return auth.currentUser.email;
    }

    const displayMessage = () => {
        setViewModals({
            ...viewModals,
            messageModal: true,
            modalMessage: ['You have been sent a password reset email']
        });
        loadingOff();
    }

    const [disabled, setDisabled] = useState(false);

    const handlePasswordReset = (event) => {
        loadingOn()
        setDisabled(true);
        const email = getEmail();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                displayMessage();
            })
            .catch((error) => {
                loadingOff();
                console.log("error handlePasswordReset", error)
                updateErrorsList(error)
            });
    };

    if (!currentUser) {
        return null;
    }

    return (
            <Card key="myPasswordResetForm">
                <Grid container spacing={2} direction="column"
                      alignItems="center"
                      justifyContent="center">
                    <FormControl>
                        <Grid><br/></Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                            <FormLabel
                                id="demo-controlled-radio-buttons-group">Request a Password Reset Email</FormLabel>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                            <Button variant="contained" disabled={disabled}
                                    onClick={handlePasswordReset}>Reset Password</Button>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                    </FormControl>
                </Grid>
            </Card>
    );
}
