import {httpsCallable} from "firebase/functions";
import {newWard, wardToJSON} from "../../factories/WardFactory";
import {addressToJSON, createAddress} from "../../factories/AddressFactory";

export default async function CreateNewWard(auth, functions, values) {
    if (!auth.currentUser) {
        console.log("No current user found");
        return;
    }
    try {
        return await createNewWard(functions,
            newWard(values.name, values.location),
            createAddress(values.postageName, values.line1, values.line2, values.town, values.postcode));
    } catch (error) {
        console.error(`Error in CreateNewWard function: ${error}`);
    }
};

const createNewWard = async (functions, ward, address) => {
    const createNewWardCallable = httpsCallable(functions, 'createNewWard');
    try {
        return await createNewWardCallable({
            ward: wardToJSON(ward),
            address: addressToJSON(address)
        });
    } catch (error) {
        console.error(`Error in createNewWard function: ${error}`);
        return error
    }
};
