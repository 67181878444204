import React from 'react';
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";

export const LearnCard = ({ title, subjects }) => (
    <Card>
        <CardContent>
            <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h5" component="h3">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                        '& ul': { margin: 0, padding: 0, listStyle: 'none' }, '& li': { alignItems: 'center' }}}>
                        <ul>
                            {subjects.map((subject, index) => (
                                <li key={index}>
                                    <a href={`/learn/${subject.path}`}>
                                        {subject.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);
