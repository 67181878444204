import * as  React from 'react';
import {Card, Grid, FormControl, FormLabel, Radio, RadioGroup} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from "@mui/material/Button";
import {createUser} from "../../factories/UserFactory";
import {createPreferences} from "../../factories/PreferencesFactory";
import {createAccount} from "../../factories/AccountFactory";
import {useState} from "react";

export default function MenuButtonAnchorCard({ currentUser, accountData, setAccountData, updateAccountPreferences, updateErrorsList}) {

    const labelLeft = 'left'
    const labelRight = 'right'
    const [change, setChange] = useState(false);

    const handleMenuButtonAnchorChange = (event) => {
        try {
            const user = createUser(accountData.firstName, accountData.lastName,
                accountData.dob, accountData.leadSource)
            const updatedPreferences = createPreferences(accountData.preferences.menuDrawerAnchor, event.target.value);
            setAccountData(createAccount(user, updatedPreferences))
            if (currentUser) {
                setChange(true)
            }
        } catch (error) {
            console.log("error handleMenuButtonAnchorChange", error)
            updateErrorsList(error)
        }

    };

    const displayChange = () => {
        try {
            if (change) {
                return (<Grid container spacing={2}>
                    <Grid item xs={6} lg={6} md={6} sm={6} xl={6} textAlign="center">
                        <Button variant="contained" disabled={!change} onClick={submitUpdate}>Save</Button>
                    </Grid>
                    <Grid item xs={6} lg={6} md={6} sm={6} xl={6} textAlign="center">
                        <Button variant="contained" disabled={!change} onClick={cancelUpdate}>Cancel</Button>
                    </Grid>
                </Grid>)
            }
        } catch (error) {
        console.log("error displayChange", error);
        updateErrorsList(error)
    }};

    const submitUpdate = () => {
        setChange(false)
        updateAccountPreferences(accountData)
    }
    const cancelUpdate = () => {
        setChange(false)
    }

    return (
            <Card aria-label="Menu Button Anchor Form Card" id="MenuButtonAnchorCard" key="menuButtonAnchorForm">
                <Grid container spacing={2} direction="column"
                      alignItems="center"
                      justifyContent="center">
                    <FormControl>
                        <Grid><br/></Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                            <FormLabel
                                id="menu-button-display">Menu Button Display</FormLabel>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                            <RadioGroup
                                aria-label="menu button display"
                                name="controlled-radio-buttons-group"
                                value={accountData.preferences.menuButtonAnchor}
                                onChange={handleMenuButtonAnchorChange}
                            >
                                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                                    <FormControlLabel aria-label="menu button left" value={labelLeft} control={<Radio/>} label={labelLeft}/>
                                    <FormControlLabel aria-label="menu button right" value={labelRight} control={<Radio/>} label={labelRight}/>
                                </Grid>
                            </RadioGroup>
                            {displayChange()}
                        </Grid>
                    </FormControl>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
                </Grid>
            </Card>
    );
}
