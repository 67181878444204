import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Container, Grid, Popover} from "@mui/material";


export default function GenericConfirmationModal({viewModals, setViewModals, loadingOn, loadingOff, modalSx}) {


    const handleConfirm = async () => {
        try {
            loadingOn();
            setViewModals({...viewModals, genericConfirmationModal: false, genericConfirmationMessage: []});
            await viewModals.genericConfirmationFunction()
            loadingOff();
            setViewModals({
                ...viewModals,
                genericConfirmationModal: false,
                genericConfirmationMessage: [],
                genericConfirmationFunction: null
            });
        } catch (error) {
            console.log("error handleMessageModalClose", error);
        }
    }

    const handleGenericConfirmationClose = () => {
        try {
            setViewModals({
                ...viewModals,
                genericConfirmationModal: false,
                genericConfirmationMessage: [],
                genericConfirmationFunction: null
            })
        } catch (error) {
            console.log("error handleMessageModalClose", error);
        }
    }

    const printMessage = () => {
        return viewModals.genericConfirmationMessage.map((msg, index) => (
            <Grid key={index} item xs={12} lg={12} md={12} sm={12} xl={12}>
                <Typography id={"message" + index} variant="h6" component="h2" textAlign="center">
                    {msg}
                </Typography>
            </Grid>))
    }

    return (
        <Popover
            open={viewModals.genericConfirmationModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleGenericConfirmationClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="message modal"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    {printMessage()}
                    <Grid item xs={6} lg={6} md={6} sm={6} xl={6} textAlign="center">
                        <Button id="confirm-message" aria-label="confirm message" variant="contained"
                                onClick={() => handleConfirm()}>Confirm</Button>
                        <Button id="close-message" aria-label="close message" variant="contained"
                                onClick={() => handleGenericConfirmationClose()}>Cancel</Button>
                    </Grid>
                </Grid>
            </Container>
        </Popover>
    );
}
