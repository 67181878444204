import * as React from 'react';
import {Modal, Grid, Popover, Container} from "@mui/material";


import DoesStringNotEqual from "../../functions/DoesStringNotEqual";
import RegexLengthCheck from "../../functions/RegexLengthCheck";
import AddressCheck from "../../functions/AddressCheck";

import EnterWardDetails from "../components/EnterWardDetails";
import AddressDisclaimer from "../components/disclaimers/AddressDisclaimer";
import NominatedPersonDisclaimer from "../components/disclaimers/NominatedPersonDisclaimer";
import EnterAddress from "../components/address/EnterAddress";
import EnterAddressConfirmation from "../components/address/EnterAddressConfirmation";
import WardReview from "../components/WardReview";
import WordSeedDisclaimer from "../components/disclaimers/WordSeedDisclaimer";

import {initWardValues} from "../../factories/InitWardValuesFactory";

export default function NewWardModal({
                                         createNewWard,
                                         viewModals, setViewModals,
                                         loadingOff, loadingOn,
                                         updateErrorsList, modalSx
                                     }) {

    const [page, setPage] = React.useState(1);
    const [values, setValues] = React.useState(initWardValues());

    const handleCheckAddress = () => {
        return AddressCheck(values, updateErrorsList);
    }

    const handleSubmit = () => {
        try {
            loadingOn();
            if (RegexLengthCheck(values.name, "Ward Name", 12)) {
                if (DoesStringNotEqual(values.location, '', "You must include a Ward Location")) {
                    if (RegexLengthCheck(values.postageName, "Postage Name", 64)) {
                        if (RegexLengthCheck(values.line1, "Address Line 1", 64)) {
                            if (RegexLengthCheck(values.town, "Town", 64)) {
                                if (RegexLengthCheck(values.postcode, "Post Code", 7)) {
                                    createNewWard(values).then(() => {
                                        handleModalClose()
                                    });
                                }
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.log("error handleNewWardModal", error)
            updateErrorsList(error)
        }
    }

    const handleModalClose = () => {
        try {
            setViewModals({
                ...viewModals,
                newWardModal: false,
            });
            setPage(1);
            setValues(initWardValues());
            loadingOff();
        } catch (error) {
            console.log("error handleClose", error)
            updateErrorsList(error)
        }
    }

    // 1 EnterWardDetails
    // 2 WardNote1
    // 3 WardNote2
    // 4 EnterAddress
    // 5 EnterAddressConfirmation
    // 6 WardReview
    // 7 WardNote3
    const handleModalPage = (n) => {
        if (n < 8 && n > 0) {
            if (n <= 2) {
                setPage(n);
            } else if (n <= 3 && values.addressDisclaimer) {
                setPage(n);
            } else if (n <= 5 && values.nominatedPersonDisclaimer && values.addressDisclaimer) {
                setPage(n);
            } else if (n <= 7 && handleCheckAddress() && values.nominatedPersonDisclaimer && values.addressDisclaimer) {
                setPage(n);
            }
        }
    }

    const wardModalPage = () => {
        switch (page) {
            case 1:
                return (<EnterWardDetails
                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    nextPage={2}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>);
            case 2:
                return <AddressDisclaimer
                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={1}
                    nextPage={3}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
            case 3:
                return <NominatedPersonDisclaimer
                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={2}
                    nextPage={4}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
            case 4:
                return <EnterAddress
                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={3}
                    nextPage={5}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
            case 5:
                return <EnterAddressConfirmation
                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={4}
                    nextPage={6}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
            case 6:
                return <WardReview
                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    prevPage={5}
                    nextPage={7}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
            case 7:
                return <WordSeedDisclaimer
                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    handleSubmit={handleSubmit}
                    handleModalClose={handleModalClose}/>;
            default:
                return <EnterWardDetails
                    values={values}
                    setValues={setValues}
                    updateErrorsList={updateErrorsList}
                    nextPage={2}
                    handleModalPage={handleModalPage}
                    handleModalClose={handleModalClose}/>;
        }
    }

    return (
        <Popover
            key={0}
            open={viewModals.newWardModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="create new ward"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    {wardModalPage()}
                </Grid>
            </Container>
        </Popover>
    );
}
