import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Container, Grid, Popover} from "@mui/material";


export default function ErrorModal({errorHandling, setErrorHandling, modalSx}) {

    const handleErrorModalClose = () => {
        try {
            setErrorHandling({
                ...errorHandling,
                errorModal: false,
                errorsList: []
            });
        } catch (error) {
            console.log("error handleErrorModalClose", error);
        }
    }

    const printErrors = () => {
        if (errorHandling.errorsList && errorHandling.errorsList.length) {
            return errorHandling.errorsList.map((error, index) => (
                <Grid key={index} item xs={12} lg={12} md={12} sm={12} xl={12}>
                    <Typography id={"error" + index} variant="h6" component="h2" textAlign="center">
                        {index + 1} - {error}
                    </Typography>
                </Grid>
            ))
        }
    }

    return (
        <Popover
            open={errorHandling.errorModal}
            anchorEl={document.getElementById('modals')}
            onClose={handleErrorModalClose}
            anchorReference={'anchorPosition'}
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            aria-label="error modal"
        >
            <Container>
                <Grid container spacing={2} direction="column" textAlign={"center"}
                      sx={{justifyContent: "center", alignContent: "center", padding: 5}}
                >
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <Typography id="error-modal-title" variant="h5" component="h2" textAlign="center">
                            Something went wrong:
                        </Typography>
                    </Grid>
                    {printErrors()}
                    <Grid id="space2" item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                        <Button variant="contained" onClick={handleErrorModalClose}>OK</Button>
                    </Grid>
                </Grid>
            </Container>
        </Popover>
    );
}
