export function createAddress(name, line1, line2, town, postcode) {
    return {
        name: name,
        line1: line1,
        line2: line2,
        town: town,
        postcode: postcode,
    };
};

export function addressToJSON(address) {
    return {
        name: address.name,
        line1: address.line1,
        line2: address.line2,
        town: address.town,
        postcode: address.postcode
    }
};
