import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {FormControl, Grid, InputLabel, OutlinedInput} from "@mui/material";

export default function EnterAddress(state) {

    const handleNextPage = () => {
        state.handleModalPage(state.nextPage);
    }

    const handleBackPage = () => {
        state.handleModalPage(state.prevPage);
    }

    const handleClosePage = () => {
        state.handleModalClose();
    }

    const handleSetWardValues = (prop) => (event) => {
        try {
            state.setValues({...state.values, [prop]: event.target.value});
        } catch (error) {
            console.log("error handleSetWardValues", error)
            state.updateErrorsList(error)
        }
    };

    const detailsNotEntered = () => {
        return state.values.postageName === '' || state.values.line1 === ''
            || state.values.town === '' || state.values.postcode === '';
    }

    const enterAddress = () => {
        return <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
                Create New Ward - Address
            </Typography>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                <FormControl id="ward-postage-name-label" label="ward-postage-name-label" variant="outlined"
                             fullWidth={true}>
                    <InputLabel htmlFor="ward-postage-name-label">Postage Name</InputLabel>
                    <OutlinedInput
                        id="ward-postage-name-input"
                        inputProps={{
                            maxLength: 64,
                            'aria-label': 'postage name'
                        }}
                        value={state.values.postageName}
                        onChange={handleSetWardValues('postageName')}
                        label="Postage Name"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                <FormControl id="ward-line1-label" label="ward-line1-label" variant="outlined" fullWidth={true}>
                    <InputLabel htmlFor="ward-line1-label">Address Line 1</InputLabel>
                    <OutlinedInput
                        id="ward-line1-input"
                        inputProps={{
                            maxLength: 64,
                            'aria-label': 'address line 1'
                        }}
                        value={state.values.line1}
                        onChange={handleSetWardValues('line1')}
                        label="Address Line 1"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                <FormControl id="ward-line2-label" label="ward-line2-label" variant="outlined" fullWidth={true}>
                    <InputLabel htmlFor="ward-line2-label">Address Line 2</InputLabel>
                    <OutlinedInput
                        id="ward-line2-input"
                        inputProps={{
                            maxLength: 64,
                            'aria-label': 'address line 2'
                        }}
                        value={state.values.line2}
                        onChange={handleSetWardValues('line2')}
                        label="Address Line 2"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                <FormControl id="ward-town-label" label="ward-town-label" variant="outlined" fullWidth={true}>
                    <InputLabel htmlFor="ward-town-label">Town</InputLabel>
                    <OutlinedInput
                        id="ward-town-input"
                        inputProps={{
                            maxLength: 64,
                            'aria-label': 'town'
                        }}
                        value={state.values.town}
                        onChange={handleSetWardValues('town')}
                        label="Town"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} alignItems="center" justify="center">
                <FormControl id="ward-postcode-label" label="ward-postcode-label" variant="outlined" fullWidth={true}>
                    <InputLabel htmlFor="ward-name-label">Post Code</InputLabel>
                    <OutlinedInput
                        id="ward-postcode-input"
                        inputProps={{
                            maxLength: 7,
                            'aria-label': 'post code'
                        }}
                        value={state.values.postcode}
                        onChange={handleSetWardValues('postcode')}
                        label="Post Code"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}><br/></Grid>
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} textAlign="center">
                    <Button variant="contained"
                            id="next-create-ward-page"
                            onClick={handleNextPage} disabled={detailsNotEntered()}>Next</Button>
                    <Button variant="contained"
                            id="back-create-ward-page"
                            onClick={handleBackPage}>Back</Button>
                    <Button variant="contained"
                            id="cancel-create-ward-page"
                            onClick={handleClosePage}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }

    return enterAddress();
}
