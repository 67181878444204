import {httpsCallable} from "firebase/functions";

export default function RedirectToBillingPortal(loadingOn, functions) {
    const redirectToBillingPortal = async () => {
        loadingOn();
        const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-rtp0-createPortalLink');

        const {data} = await functionRef({
            returnUrl: window.location.origin,
            locale: "auto",
        });
        window.location.assign(data.url);
    }
    redirectToBillingPortal();
}
