import {
    Button,
    Card,
    CardMedia,
    Grid,
    IconButton,
    Input,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import CheckoutSession from '../../services/subscriptionService/CheckoutSession';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const OnetimeTableItem = ({
                              plan,
                              payments,
                              loadingOn,
                              updateErrorsList,
                          }) => {
    const [qty, setQty] = useState(1);

    const checkout = () => {
        loadingOn();
        CheckoutSession(payments, plan.prices[0].id, 'onetime_purchase', qty);
    };

    const increment = () => setQty((prevQty) => prevQty + 1);
    const decrement = () => qty > 1 && setQty((prevQty) => prevQty - 1);

    return (
        <Grid container spacing={2} justifyContent="center">
            <Card key={plan.name} sx={{ mt: 2 }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item container direction="row" alignItems="center" justifyContent="center">
                                <Grid item container direction="row" alignItems="center" justifyContent="center">
                                    {plan.images[0] && (
                                        <CardMedia
                                            sx={{
                                                mt: 2,
                                                ml: 4,
                                                mr: 4,
                                                mb: 2,
                                                height: '25%',
                                                width: '25%',
                                                borderRadius: 60,
                                            }}
                                            component="img"
                                            image={plan.images[0]}
                                            alt={plan.name}
                                        />
                                    )}
                                    <Typography variant="h6">{qty > 1 ? `${qty * 10} WARD` : plan.name}</Typography>
                                </Grid>
                                <Grid item container direction="column" alignItems="center" justifyContent="center">
                                    <Typography variant="body2">{plan.description}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                            <IconButton onClick={decrement}  aria-label="decrease quantity">
                                <RemoveIcon />
                            </IconButton>
                            <Input value={qty} readOnly />
                            <IconButton onClick={increment}  aria-label="increase quantity">
                                <AddIcon />
                            </IconButton>
                        </Grid>

                        <Grid item container direction={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography variant="h4">
                                    £{((plan.prices[0].unit_amount ? plan.prices[0].unit_amount / 100 : 0) * qty).toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" size="large" onClick={() => checkout()}>
                                    Buy
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
        </Grid>
    );
};

const OnetimeTableItems = ({ products, payments, loadingOn, updateErrorsList }) => {
    return (
        <Grid container direction="row" spacing={3} justifyContent="center" alignItems="center">
            {products.onetimeProducts?.map((plan, index) => (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={plan.name}>
                    <OnetimeTableItem
                        plan={plan}
                        payments={payments}
                        loadingOn={loadingOn}
                        updateErrorsList={updateErrorsList}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default OnetimeTableItems;
