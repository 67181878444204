import {Button, Grid} from "@mui/material";
import * as React from 'react';
import Divider from "@mui/material/Divider";

export default function VerifyReceived(state) {

    const receivedButton = () => {
        if (state.ward.status === "in transit to you") {
            return <Grid container
                         alignItems="center"
                         direction="column"
                         spacing={2}>
                <Divider orientation="horizontal" flexItem style={{margin: 6}}/>
                <Button variant="contained" id='received' onClick={e => state.handleDisplayVerifyReceivedModal(state.ward.id,true)}>Received</Button>
            </Grid>
        }
    }

    return (receivedButton());
}
