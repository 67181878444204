import {Container, Grid,} from "@mui/material";
import PointBalanceDetails from "../components/myPointComponents/PointBalanceDetails";
import PointTransactions from "../components/myPointComponents/PointTransactions";

export default function MyPoints({
                                     pointBalance, products, payments, auth, db, pointsTransactions, setPointsTransactions,
                                     subscription, wards, currentUser, loadingOn, updateErrorsList
                                 }) {


    if (!currentUser) {
        return null;
    }
    return (
        <Container>
            <Grid container
                  spacing={2}
                  direction="column" justifyContent={"center"} alignContent={"center"}>
                    <PointBalanceDetails currentUser={currentUser} payments={payments} products={products}
                                         showOneTimeProduct={true} productDirection={"row"}
                                         loadingOn={loadingOn}
                                         subscription={subscription} wards={wards} pointBalance={pointBalance}
                                         updateErrorsList={updateErrorsList}/>
                    <PointTransactions auth={auth} db={db} pointsTransactions={pointsTransactions}
                                       setPointsTransactions={setPointsTransactions}
                                       updateErrorsList={updateErrorsList}/>
            </Grid>
        </Container>
    );
}
