import React from 'react';
import {Card, CardContent, CardMedia, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

const NotFound = () => {
    return (
        <Grid container spacing={3} alignContent="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Card>
                    <CardContent>
                        <Grid>
                            <Grid>
                                <Typography variant="h1">
                                    404
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="h2">
                                  Page Not Found
                                </Typography>
                            </Grid>
                            <Grid sx={{mt: 5}}>
                                <Typography variant="body1">
                                    Sorry, the page you are looking for does not exist.
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Card>
                    <CardMedia component="img" image={'/404.png'}/>
                </Card>
            </Grid>
        </Grid>
    );
};

export default NotFound;
